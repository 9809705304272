export interface FnsToken {
  permission_request_id: string
  acquirer_alias: string
  merchantId: string
  acceptedReturnUrl: string
  refusedReturnUrl: string
}

export const parseToken = (token: string): null | FnsToken => {
  if (!token) {
    console.error('Token is required')

    return null
  }

  try {
    const {
      prid: permission_request_id,
      aa: acquirer_alias,
      mid: merchantId,
      aurl: acceptedReturnUrl,
      rurl: refusedReturnUrl,
    } = JSON.parse(atob(token.replace(/-/g, '+').replace(/_/g, '/')))

    return {
      permission_request_id,
      acquirer_alias,
      merchantId,
      acceptedReturnUrl,
      refusedReturnUrl,
    }
  } catch (error) {
    console.error(error)

    return null
  }
}
