export default [
  {
    alias: 'moneta',
    name: 'НКО «МОНЕТА»',
    logo: 'moneta_logo_dark.svg',
  },
  {
    alias: 'ckassa',
    name: 'ООО ПНКО «ЭЛПЛАТ» (технолог Twikli)',
    logo: 'elecsnet_logo.svg',
  },
  {
    alias: 'pay2pro',
    name: 'ТКБ Банк ПАО',
    logo: 'tkb-logo.svg',
  },
  {
    alias: 'mobi-vtb',
    name: 'ООО НКО «МОБИ.Деньги»<br><br>(партнер Банка ВТБ (ПАО))',
    logo: 'mobi-vtb-logo.svg',
  },
  {
    alias: 'b2p',
    name: 'ООО «Бест2пей»<br><br>(партнер ПАО «Совкомбанк»)',
    logo: 'best2pay.png',
  },
]
