<template>
  <main class="fns-selfemployed info-container">
    <section class="fns-selfemployed-head">
      <div class="fns-selfemployed-head__logo">
        <img :src="logo" />
      </div>
      <div class="fns-selfemployed-head__name">{{ config.name }}</div>
      <br />
      <br />
      <h1>Отлично!</h1>
      <section class="fns-selfemployed__list-title">
        Дождитесь смс со ссылкой и кодом. <br />
        Чтобы завершить <br />
        подключение, перейдите по ссылке, введите код и <br />
        дополнительную информацию.
      </section>
    </section>
    <div class="confirm-button mb-3">
      <v-btn block elevation="2" large color="primary" @click="confirm">ЗАКРЫТЬ</v-btn>
    </div>
  </main>
</template>

<script>
import cfg from '@/config/selfemployed'

export default {
  name: 'PostConfirmInfo',
  computed: {
    config: function () {
      return cfg.find(
        (c) => c.alias.toLowerCase() === this.$route.params.alias.toLowerCase(),
      )
    },
    logo: function () {
      return require(`../../../assets/img/${this.config.logo}`)
    },
  },
  methods: {
    confirm() {
      this.$store.commit('pay/receiveMerchantPermission', { result: 'success' })
    },
  },
}
</script>
<style scoped>
.info-container {
  justify-content: space-between;
  flex-direction: column;
  display: flex;
}
.confirm-button {
  justify-content: flex-end;
  display: flex;
}
</style>
