import { Block } from '@/constants'
import { SET_LANG } from '../mutations.type'

export default {
  state: () => ({
    lang: null,
  }),
  mutations: {
    [SET_LANG]: (state, { lang }) => (state.lang = lang.toUpperCase()),
  },
  getters: {
    headerBlock: (state, getters, rootState) =>
      rootState.page?.getBlock(Block.HeaderBlock),
    headerLogoUrl: (state, getters) => ({
      light: getters.headerBlock.body.find((b) => b.name === 'light_logo_url').value,
      dark: getters.headerBlock.body.find((b) => b.name === 'dark_logo_url').value,
    }),
    langs: (state, getters) =>
      getters.headerBlock?.body
        .find((b) => b.name === 'language_list')
        .value.map((l) => l.toUpperCase()),
  },
}
