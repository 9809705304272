import {
  MENU_INIT,
  MENU_ADD,
  MENU_REMOVE,
  SET_TAG,
  MENU_CLEAR,
  PAGE_TEMPLATE_INIT,
  ITEMS_INIT,
  ITEMS_ADD,
  ITEMS_REMOVE,
  // ITEMS_CLEAR,
} from '../mutations.type'
import { Block } from '@/constants'

const localProducts = (page_id) => {
  const products = []
  Object.keys(localStorage)
    .filter((item) => item.split('_')[0] === page_id)
    .forEach((product) => products.push(JSON.parse(localStorage.getItem(product))))
  return products
}

const updateLocalProduct = (product, count, page_id) => {
  count
    ? localStorage.setItem(
        `${page_id}_${product.id}`,
        JSON.stringify({
          ...product,
          count,
        }),
      )
    : localStorage.removeItem(`${page_id}_${product.id}`)
}

const updateLocalItemsProduct = (product, count, page_id, index) => {
  product.prices[index]['name'] = product.name
  count
    ? localStorage.setItem(
        `${page_id}_${product.id}_${index}`,
        JSON.stringify({
          ...product.prices[index],
          count,
        }),
      )
    : localStorage.removeItem(`${page_id}_${product.id}_${index}`)
} //Для AvaliableItemsBlock

export default {
  state: () => ({
    tag: null,
    qty: {},
    total: 0,
    page: null,
    pageTemplate: null,
  }),
  getters: {
    menuBlock: (state, getters, rootState) =>
      rootState.page.getBlock(Block.AvailableMenuBlock, rootState.route.current),
    itemsBlock: (state, getters, rootState) =>
      rootState.page.getBlock(Block.AvailableItemsBlock, rootState.route.current),
    menuBlockMain: (state, getters, rootState) =>
      rootState.page.getBlock(Block.AvailableMenuBlock),
    itemsBlockMain: (state, getters, rootState) =>
      rootState.page.getBlock(Block.AvailableItemsBlock),
    total: (state) => state.total,
    pagePath: (state) => state.page,
    pageTemplate: (state) => state.pageTemplate,
    localProducts: (state) =>
      localProducts(state.pageTemplate.template_id).map((product) => ({
        id: product.id,
        name: product.name,
        price: product.price,
        quantity: product.count,
      })),
  },
  mutations: {
    [MENU_INIT](state, { block, page, template }) {
      state.qty = {}
      state.total = 0
      if (!Object.keys(state.qty).length) {
        state.page = page
        state.pageTemplate = template
        const items = block.body.find((b) => b.name === 'items').value
        for (let item of items) {
          const name = item.name
          state.qty[name] =
            localProducts(state.pageTemplate.template_id).find((product) => product.name === name)?.count ||
            0
          state.total +=
            state.qty[name] *
              localProducts(state.pageTemplate.template_id).find((product) => product.name === name)
                ?.price || 0
        }
      }
    },
    [MENU_ADD](state, product) {
      const { name, price } = product
      state.qty = { ...state.qty, [name]: state.qty[name] + 1 }
      state.total += price
      updateLocalProduct(product, state.qty[name], state.pageTemplate.template_id)
      state.totalError = !state.total
    },
    [MENU_REMOVE](state, product) {
      const { name, price } = product
      state.qty = { ...state.qty, [name]: state.qty[name] - 1 }
      state.total -= price
      updateLocalProduct(product, state.qty[name], state.pageTemplate.template_id)
    },
    [ITEMS_INIT](state, { block, page, template }) {
      state.qty = {}
      state.total = 0
      if (!Object.keys(state.qty).length) {
        state.page = page
        state.pageTemplate = template
        const items = block.body.find((b) => b.name === 'items').value
        for (let item of items) {
          const name = item.name
          state.qty[name] = []
          for (let i = 0; i < item.prices.length; i++) {
            let configuration = name + ' ' + item.prices[i].description
            state.qty[name].push(
              localProducts(state.pageTemplate.template_id).find(
                (product) => product.name + ' ' + product.description === configuration,
              )?.count || 0,
            )

            state.total +=
              state.qty[name][i] *
                localProducts(state.pageTemplate.template_id).find(
                  (product) => product.name + ' ' + product.description === configuration,
                )?.price || 0
          }
        }
      }
    },
    [ITEMS_ADD](state, productData) {
      let index = productData.index,
        name = productData.product.name,
        price = productData.product.prices[index].price
      state.qty[name][index] += 1
      state.total += price
      updateLocalItemsProduct(productData.product, state.qty[name][index], state.pageTemplate.template_id, index)
      state.totalError = !state.total
    },
    [ITEMS_REMOVE](state, productData) {
      let index = productData.index,
        name = productData.product.name,
        price = productData.product.prices[index].price
      state.qty[name][index] -= 1
      state.total -= price
      updateLocalItemsProduct(productData.product, state.qty[name][index], state.pageTemplate.template_id, index)
    },
    [MENU_CLEAR](state) {
      for (const key in state.qty) {
        state.qty[key] = 0
      }
      state.total = 0
    },
    [SET_TAG]: (state, tag) => (state.tag = tag),
    [PAGE_TEMPLATE_INIT]: (state, data) => (state.pageTemplate = data),
  },
}
