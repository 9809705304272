import Vue from 'vue'
import Vuex from 'vuex'

import { getDecimalSeparator, validateEmail } from '../utils'
import header from './modules/header'
import merchant from './modules/merchant'
import menu from './modules/menu'
import checkout from '@/store/modules/checkout'
import pay from '@/store/modules/pay'
import {
  SET_VALUE,
  VALIDATE,
  PRODUCT_ADD,
  PRODUCT_REMOVE,
  RECEIVE_PRODUCTS,
  SET_LOADING,
  SET_FETCHING,
  SET_TAG,
  RECEIVE_PAGE,
  MENU_INIT,
  ITEMS_INIT,
  SET_LANG,
  FEEDBACK_CHANGE,
  SET_CASE_NAME,
  PAGE_TEMPLATE_INIT,
  TIPS_BUTTONS_INIT,
  SET_ROUTE,
  CHANGE_TOTAL,
  CHANGE_TOTAL_ERROR,
  TIPS_BUTTONS_CHANGE,
  TICKETS_EVENT_INIT,
  TICKETS_LIST_INIT,
} from './mutations.type'
import { invoicePaymentData } from '@/api/fns'
import { REQUEST_PAGE, REQUEST_PRODUCTS } from './actions.type'
import { getTemplate } from '@/api/template'
import { Block } from '@/constants'

Vue.use(Vuex)

const getPageName = (page, route) => {
  const name = []
  name.push(page?.case)
  name.push(
    page?.pages
      .find((p) => p.name === route)
      ?.blocks.find((b) => b.name === Block.MerchantBlock)
      ?.body.find((item) => item.name === 'name')?.value,
  )
  return name
    .filter((item) => item)
    .map((item) => item.toLowerCase())
    .join('-')
}

export default new Vuex.Store({
  state: {
    caseName: '',
    isLoading: false,
    isFetching: false,
    decSep: getDecimalSeparator(),
    values: {
      isValid: true,
      amount: '',
      selectedAmount: '200',
      email: '',
      phone: '',
      sendType: 'email',
      payMethod: null,
    },
    saleInfo: [],
    totalAmount: 0,
    merchantName: '',
    validation: {
      amount: '',
      email: '',
    },
    amounts: ['200', '500', '1000', '1500', '2000'],
    page: null,
    totalError: false,
    amountButtons: [],
    route: '',
    event: null,
    tickets: null,
  },
  actions: {
    async [REQUEST_PAGE]({ commit, state }, { aliasOrTemplate }) {
      commit(SET_LOADING, true)
      try {
        const page = await getTemplate(aliasOrTemplate)
        commit(PAGE_TEMPLATE_INIT, page)
        commit(SET_CASE_NAME, page.case)
        commit(SET_LOADING, false)
        commit(RECEIVE_PAGE, { page })
        const headerBlock = page.getBlock(Block.HeaderBlock)
        const menuBlock = page.getBlock(Block.AvailableMenuBlock)
        const itemsBlock = page.getBlock(Block.AvailableItemsBlock)
        if (headerBlock) {
          const firstLang = headerBlock.body.find((b) => b.name === 'language_list')
            .value[0]
          commit(SET_LANG, { lang: firstLang })
        }

        if (
          page.case === 'hotels' ||
          page.case === 'custom-amount' ||
          page.case === 'order' ||
          page.case === 'menu'
        ) {
          if (menuBlock) {
            const firstTag = menuBlock.body[0].value[0].item_tags[0]
            commit(SET_TAG, firstTag)
            commit(MENU_INIT, {
              block: menuBlock,
              page: getPageName(page, page.case),
              template: page,
            })
          } else if (itemsBlock) {
            const firstTag = itemsBlock.body[0].value[0].item_tags[0]
            commit(SET_TAG, firstTag)
            commit(ITEMS_INIT, {
              block: itemsBlock,
              page: getPageName(page, page.case),
              template: page,
            })
          }
          const feedback = page.getBlock(Block.FeedbackBlock, state.route.current)
          if (feedback) commit(FEEDBACK_CHANGE, feedback)
        }

        if (page.case === 'tips' || page.case === 'donation') {
          const buttonsBlock = page.getBlock(Block.AvailableMenuBlock)
          if (buttonsBlock) commit(TIPS_BUTTONS_INIT, buttonsBlock)
        }

        if (page.case === 'tickets') {
          if (menuBlock) {
            commit(MENU_INIT, {
              block: menuBlock,
              page: getPageName(page, page.case),
              template: page,
            })
          } else if (itemsBlock) {
            commit(ITEMS_INIT, {
              block: itemsBlock,
              page: getPageName(page, page.case),
              template: page,
            })
          }
          const event = page.getBlock(Block.EventBlock)
          if (event) commit(TICKETS_EVENT_INIT, event)
          const tickets = page.getBlock(Block.AvailableMenuBlock)
          if (tickets) commit(TICKETS_LIST_INIT, tickets)
        }
        // if (page.case === "fix") {
        //   let cartBlock = {
        //     ...page,
        //     template_id: JSON.parse(localStorage.getItem("templateId")),
        //   };
        //   postInvoiceNumber(cartBlock);
        // }
      } catch (error) {
        // TODO: обработка ошибок
        console.error(error)
        throw error
      }
    },
    async [REQUEST_PRODUCTS]({ commit }, { invoiceId }) {
      const { saleInfo, totalAmount, merchantName } = await invoicePaymentData(invoiceId)
      commit(RECEIVE_PRODUCTS, { saleInfo, totalAmount, merchantName })
    },
  },
  mutations: {
    [SET_ROUTE](state, route) {
      const main = route.path.replace('/', '').split('/').shift()
      const current = route.path.replace('/', '').split('/').pop()
      state.route = {
        ...route,
        current: main === 'p' ? route.params.pageName : current,
      }
    },
    [SET_CASE_NAME]: (state, pageCase) => (
      state.caseName = pageCase
    ),
    [TIPS_BUTTONS_INIT]: (state, data) => (state.amountButtons = data?.body[0]?.value),
    [TIPS_BUTTONS_CHANGE]: (state, data) => (state.amountButtons = data),
    [SET_LOADING](state, value) {
      state.isLoading = value
    },
    [SET_FETCHING](state, value) {
      state.isFetching = value
    },
    [SET_VALUE](state, { key, value }) {
      state.values[key] = value
      state.validation[key] = ''
    },
    [VALIDATE](state) {
      const isEmailValid =
        state.values.sendType === 'phone' || validateEmail(state.values.email)
      if (!isEmailValid) {
        state.validation['email'] = 'Вы ввели некорректный e-mail'
      }
    },
    [PRODUCT_ADD](state, name) {
      const product = state.saleInfo.find((p) => p.name === name)
      product.quantity += 1
    },
    [PRODUCT_REMOVE](state, name) {
      const product = state.saleInfo.find((p) => p.name === name)
      product.quantity -= 1
    },
    [RECEIVE_PRODUCTS](state, { saleInfo, totalAmount, merchantName }) {
      state.saleInfo = saleInfo
      state.totalAmount = totalAmount
      state.merchantName = merchantName
      state.isLoading = false
    },
    [RECEIVE_PAGE]: (state, { page }) => (state.page = page),
    [CHANGE_TOTAL]: (state, data) => (state.totalAmount = data),
    [CHANGE_TOTAL_ERROR]: (state, data) => (state.totalError = data),
    [TICKETS_EVENT_INIT]: (state, data) => (state.event = data),
    [TICKETS_LIST_INIT]: (state, data) => (state.tickets = data),
  },
  // TODO удалить ненужные, использовать mapState
  getters: {
    caseName: (state) => state.caseName,
    isComplete(state, getters) {
      const rePhone = /^\+7\s\(\d\d\d\)\s\d\d\d-\d\d-\d\d$/
      return (
        (state.values.amount !== '' || Number(getters.computedTotalAmount) > 0) &&
        ((state.values.sendType === 'phone' && rePhone.test(state.values.phone)) ||
          (state.values.sendType === 'email' && state.values.email.trim() !== ''))
      )
    },
    decSep: (state) => state.decSep,
    amount: (state) => state.values.amount,
    amounts: (state) => state.amounts,
    selectedAmount: (state) => state.values.selectedAmount,
    email: (state) => state.values.email,
    phone: (state) => state.values.phone,
    sendType: (state) => state.values.sendType,
    isValid: (state) => state.values.isValid,
    payMethod: (state) => state.values.payMethod,
    validation: (state) => state.validation,
    saleInfo: (state) => state.saleInfo,
    totalAmount: (state) => state.totalAmount,
    computedTotalAmount: (state) =>
      state.saleInfo.reduce((acc, p) => acc + Number(p.cost) * p.quantity, 0),
    amountButtons: (state) => state.amountButtons,
    totalError: (state) => state.totalError,
    route: (state) => state.route,
    event: (state) => state.event?.body,
    tickets: (state) => state.tickets?.body,
  },
  modules: {
    header,
    merchant,
    menu,
    checkout,
    pay,
  },
})
