import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/fns/Home.vue'
import FnsPayment from '../views/fns/Payment.vue'
import FnsBinding from '../views/fns/Binding.vue'
import FnsSuccess from '../views/fns/Success.vue'
import FnsFail from '../views/fns/Fail.vue'
import FnsSelfemployedConfirm from '../views/fns/SelfemployedConfirm.vue'
import FnsNotFound from '../views/fns/NotFound.vue'
import TermsOfUse from '../views/fns/TermsOfUse.vue'
import PostConfirmInfo from '../views/fns/specific/PostConfirmInfo.vue'
import IFrameLoader from '../views/fns/status/IFrameLoader.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/pay/:invoiceId',
    component: FnsPayment,
  },
  {
    path: '/status/iframe-loader',
    component: IFrameLoader,
  },
  {
    path: '/success',
    component: FnsSuccess,
  },
  {
    path: '/fail',
    component: FnsFail,
  },
  {
    path: '/selfemployed/confirm/:token',
    component: FnsSelfemployedConfirm,
  },
  {
    path: '/bind/card/:internalId',
    component: FnsBinding,
  },
  {
    name: 'post-confirm',
    path: '/selfemployed/post-confirm/:alias',
    component: PostConfirmInfo,
  },
  {
    name: 'terms-of-use',
    path: '/terms_of_use/:alias',
    component: TermsOfUse,
  },
  {
    path: '/*',
    component: FnsNotFound,
  },
]

const router = new VueRouter({
  routes,
  base: '/fns/',
})

export default router
