// см. https://developers.google.com/pay/api/web/guides/tutorial#authorize-payments

import { GooglepayProgressData } from '@/api/fns'

const countryCode = 'RU'
const currencyCode = 'RUB'

const isDevelopment = process.env.NODE_ENV === 'development'

const env = isDevelopment ? 'TEST' : 'PRODUCTION'

/**
 * Define the version of the Google Pay API referenced when creating your
 * configuration
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#PaymentDataRequest|apiVersion in PaymentDataRequest}
 */
const baseRequest: Pick<
  google.payments.api.PaymentDataRequest,
  'apiVersion' | 'apiVersionMinor' | 'allowedPaymentMethods'
> = {
  apiVersion: 2,
  apiVersionMinor: 0,
  allowedPaymentMethods: [] as google.payments.api.PaymentMethodSpecification[],
}

type GatewayParams = google.payments.api.PaymentGatewayTokenizationParameters & {
  gateway: string
  gatewayMerchantId: string
}

/**
 * Identify your gateway and your site's gateway merchant identifier
 *
 * The Google Pay API response will return an encrypted payment method capable
 * of being charged by a supported gateway after payer authorization
 *
 * @todo check with your gateway on the parameters to pass
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#gateway|PaymentMethodTokenizationSpecification}
 */
function getTokenizationSpecification(
  parameters: GatewayParams,
): google.payments.api.PaymentMethodTokenizationSpecification {
  return {
    type: 'PAYMENT_GATEWAY',
    parameters,
  }
}
/**
 * Card networks supported by your site and your gateway
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
 * @todo confirm card networks supported by your site and gateway
 */
const allowedCardNetworks: google.payments.api.CardNetwork[] = ['MASTERCARD', 'VISA']

/**
 * Card authentication methods supported by your site and your gateway
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
 * @todo confirm your processor supports Android device tokens for your
 * supported card networks
 */
const allowedCardAuthMethods: google.payments.api.CardAuthMethod[] = ['CRYPTOGRAM_3DS']

/**
 * Describe your site's support for the CARD payment method and its required
 * fields
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
 */
const baseCardPaymentMethod: google.payments.api.IsReadyToPayPaymentMethodSpecification =
  {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: allowedCardAuthMethods,
      allowedCardNetworks: allowedCardNetworks,
    },
  }

/**
 * Describe your site's support for the CARD payment method including optional
 * fields
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
 */
function getCardPaymentMethod(
  params: GatewayParams,
): google.payments.api.PaymentMethodSpecification {
  const tokenizationSpecification = getTokenizationSpecification(params)

  return Object.assign({ tokenizationSpecification }, baseCardPaymentMethod)
}

/**
 * An initialized google.payments.api.PaymentsClient object or null if not yet set
 *
 * @see {@link getGooglePaymentsClient}
 */
let paymentsClient: null | google.payments.api.PaymentsClient = null

/**
 * Configure your site's support for payment methods supported by the Google Pay
 * API.
 *
 * Each member of allowedPaymentMethods should contain only the required fields,
 * allowing reuse of this base request when determining a viewer's ability
 * to pay and later requesting a supported payment method
 *
 * @returns {object} Google Pay API version, payment methods supported by the site
 */
function getGoogleIsReadyToPayRequest() {
  return {
    ...baseRequest,
    allowedPaymentMethods: [baseCardPaymentMethod],
  }
}

/**
 * Provide Google Pay API with a payment amount, currency, and amount status
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#TransactionInfo|TransactionInfo}
 * @returns {object} transaction info, suitable for use as transactionInfo property of PaymentDataRequest
 */
function getGoogleTransactionInfo({
  totalPrice,
}: {
  totalPrice?: google.payments.api.TransactionInfo['totalPrice']
}): google.payments.api.TransactionInfo {
  if (!totalPrice) {
    // @ts-ignore totalPrice не обязателен для этого вида транзакции
    return {
      totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
      currencyCode,
    }
  }
  return {
    countryCode,
    currencyCode,
    totalPriceStatus: 'FINAL',
    totalPrice,
  }
}

type PaymentDataRequestParams = google.payments.api.MerchantInfo & {
  totalPrice?: google.payments.api.TransactionInfo['totalPrice']
}

/**
 * Configure support for the Google Pay API
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#PaymentDataRequest|PaymentDataRequest}
 * @returns {object} PaymentDataRequest fields
 */
function getGooglePaymentDataRequest(
  params: PaymentDataRequestParams & GatewayParams,
): google.payments.api.PaymentDataRequest {
  const cardPaymentMethod = getCardPaymentMethod(params)
  const paymentDataRequest: google.payments.api.PaymentDataRequest = {
    ...baseRequest,
    allowedPaymentMethods: [cardPaymentMethod],
    transactionInfo: getGoogleTransactionInfo({
      totalPrice: params.totalPrice,
    }),
    merchantInfo: {
      // @todo a merchant ID is available for a production environment after approval by Google
      // See {@link https://developers.google.com/pay/api/web/guides/test-and-deploy/integration-checklist|Integration checklist}
      merchantId: params.merchantId,
      merchantName: params.merchantName,
    } as google.payments.api.MerchantInfo,
    callbackIntents: ['PAYMENT_AUTHORIZATION'],
  }

  return paymentDataRequest
}

let paymentAuthorizedCallback: null | {
  (paymentData: google.payments.api.PaymentData): Promise<GooglepayProgressData>
} = null

let successCallback: null | { (): void }

async function onPaymentAuthorized(
  paymentData: google.payments.api.PaymentData,
): Promise<google.payments.api.PaymentAuthorizationResult> {
  try {
    if (paymentAuthorizedCallback === null) {
      throw new Error('paymentAuthorizedCallback is undefined')
    }
    const { status } = await paymentAuthorizedCallback(paymentData)
    if (status === 'SUCCESS') {
      if (successCallback === null) {
        throw new Error('successCallback is undefined')
      }
      successCallback()
      return {
        transactionState: 'SUCCESS',
      }
    }
    return {
      transactionState: 'ERROR',
    }
  } catch (error: any) {
    return {
      error: {
        message: error.message,
        intent: 'PAYMENT_AUTHORIZATION',
        reason: error.message,
      },
      transactionState: 'ERROR',
    }
  }
}

/**
 * Return an active PaymentsClient or initialize
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/client#PaymentsClient|PaymentsClient constructor}
 * @returns {google.payments.api.PaymentsClient} Google Pay API client
 */
function getGooglePaymentsClient(gateway?: string) {
  if (paymentsClient === null) {
    paymentsClient = new google.payments.api.PaymentsClient({
      environment: gateway === 'payler' ? 'TEST' : env,
      paymentDataCallbacks: {
        onPaymentAuthorized,
      },
    })
  }
  return paymentsClient
}

/**
 * Initialize Google PaymentsClient after Google-hosted JavaScript has loaded
 *
 * Display a Google Pay payment button after confirmation of the viewer's
 * ability to pay.
 */
export async function getIsReadyToGooglePay() {
  const paymentsClient = getGooglePaymentsClient()
  try {
    const response = await paymentsClient.isReadyToPay(getGoogleIsReadyToPayRequest())
    if (response.result) {
      return true
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

/**
 * Prefetch payment data to improve performance
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/client#prefetchPaymentData|prefetchPaymentData()}
 */
// export function prefetchGooglePaymentData(
//   params: google.payments.api.MerchantInfo
// ) {
//   const paymentDataRequest = getGooglePaymentDataRequest(params);
//   const paymentsClient = getGooglePaymentsClient();
//   paymentsClient.prefetchPaymentData(paymentDataRequest);
// }

/**
 * Show Google Pay payment sheet when Google Pay payment button is clicked
 * Обернуть вызов в try/catch
 */
export async function startGooglePay(
  params: google.payments.api.MerchantInfo &
    GatewayParams & {
      totalPrice: google.payments.api.TransactionInfo['totalPrice']
    },
  onPaymentAuthorized: (
    paymentData: google.payments.api.PaymentData,
  ) => Promise<GooglepayProgressData>,
  onSuccess: () => void,
  onError: (error: Error) => void,
) {
  const paymentDataRequest = getGooglePaymentDataRequest(params)
  paymentAuthorizedCallback = onPaymentAuthorized
  successCallback = onSuccess
  const paymentsClient = getGooglePaymentsClient(params.gateway)
  try {
    const paymentData = await paymentsClient.loadPaymentData(paymentDataRequest)
    return paymentData.paymentMethodData.tokenizationData.token
  } catch (error: any) {
    onError(error)
  }
}
