<template>
  <section
    v-if="merchantBlock"
    class="merchant-block"
    :class="{ 'merchant-block_with-logo': merchantLogoUrl }"
  >
    <div class="merchant-block__name">{{ merchantName }}</div>
    <div class="merchant-block__logo">
      <div v-if="merchantLogoUrl" class="merchant-block__logo-wrap">
        <img :src="merchantLogoUrl" alt="" />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Merchant',
  computed: {
    ...mapGetters(['merchantBlock', 'merchantLogoUrl', 'merchantName']),
  },
}
</script>

<style lang="scss">
@import '~@/assets/scss/components/merchant.scss';
</style>
