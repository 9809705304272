<template>
  <header class="header" :class="{ ['header_with-merchant']: withMerchant }">
    <div class="header-top" :class="{ ['header-top_with-merchant']: withMerchant }">
      <div class="header-top__logo">
        <span>кубик</span>
      </div>
      <div class="header-top__lang">
        <div class="header-top__lang-selector" v-show="langSelectorVisible">
          <div v-for="l in langs" :key="l">
            <input
              :id="'lang-' + l"
              :value="l"
              @click="selectLang"
              name="lang"
              type="radio"
            />
            <label :for="'lang-' + l">{{ l }}</label>
          </div>
        </div>
        <div class="header-top__selected-lang" @click="toggleLangs">
          <span v-show="!langSelectorVisible">{{ lang }}</span>
        </div>
      </div>
    </div>
    <template v-if="withMerchant">
      <div class="company">{{ withMerchant.merchantName }}</div>
    </template>
  </header>
</template>

<script>
import { mapState } from 'vuex'
import header from '@/mixins/header'
import getMenuInfo from '@/mixins/getMenuInfo'

export default {
  mixins: [header, getMenuInfo],
  props: ['withMerchant', 'demo'],
  computed: {
    ...mapState({
      merchantName: (state) => state.header.merchantName,
    }),
  },
}
</script>

<style lang="scss">
@import '~@/assets/scss/variables';

.header {
  margin-bottom: 43px;
  padding: 8px;
  position: relative;
  min-height: 120px;
  color: #fff;
  background-color: $bgBlack;
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;

  &:not(.header_with-merchant) {
    min-height: auto;
    margin-bottom: 22px;
    background-color: transparent;
    color: $color;
  }
}

.header-top {
  display: flex;
  flex-flow: row nowrap;
  font-size: 11px;
  line-height: 13px;

  &__logo {
    line-height: 18px;
    font-size: 17px;
    font-weight: 400;

    > span {
      display: inline-block;
      vertical-align: top;
      margin-left: 6px;
    }
  }

  &:not(.header-top_with-merchant) {
    .header-top__logo {
      &:before {
        background-image: url('~@/assets/img/logo_black.svg');
      }
    }
  }

  &__lang {
    line-height: 18px;
    margin-left: auto;
    display: flex;
    flex-flow: row nowrap;
  }

  &__lang-selector {
    display: flex;
    flex-flow: row nowrap;

    > div {
      margin: 0 8px;
      input[type='radio'] {
        display: none;
      }

      input:checked + label {
        text-decoration: underline;
      }
    }
  }

  &__selected-lang {
    > span {
      display: inline-block;
      vertical-align: top;
      margin-left: 5px;
    }

    &:before {
      content: '';
      display: inline-block;
      width: 15px;
      height: 18px;
      background-image: url('~@/assets/img/lang.svg');
      background-position: top 2px right 0px;
      background-repeat: no-repeat;
    }
  }

  &:not(.header-top_with-merchant) {
    .header-top__selected-lang {
      &:before {
        background-image: url('~@/assets/img/lang_black.svg');
      }
    }
  }
}

.company {
  font-size: 21px;
  line-height: 25px;
  margin: 20px 50px 20px;
  text-align: center;
}

.company-logo {
  position: absolute;
  bottom: -26px;
  left: calc(50% - 26px);
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 6px $bgBlack;
  border-radius: 48px;
  background-color: #fff;
}
</style>
