<template>
  <span>{{ formattedValue }}</span>
</template>

<script>
export default {
  props: {
    currency: {
      type: String,
      default: 'RUB',
    },
    fracDigits: {
      type: Boolean,
      default: true,
    },
    locale: {
      type: String,
      default: 'ru',
    },
    value: {
      type: [String, Number],
    },
  },
  computed: {
    formattedValue: function () {
      const formatter = new Intl.NumberFormat(this.locale, {
        style: 'currency',
        currency: this.currency,
        minimumFractionDigits: this.fracDigits ? 2 : 0,
      })

      return formatter.format(this.value)
    },
  },
}
</script>
