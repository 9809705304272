<template>
  <main class="fns-pay">
    <section class="fns-pay-lang">
      <div class="fns-lang-selector" v-show="langSelectorVisible">
        <div v-for="l in langs" v-bind:key="l">
          <input
            v-bind:id="'lang-' + l"
            v-bind:value="l"
            v-on:click="selectLang"
            name="lang"
            type="radio"
          />
          <label v-bind:for="'lang-' + l">{{ l }}</label>
        </div>
      </div>
      <div class="fns-selected-lang" v-on:click="toggleLangs">
        <span v-show="!langSelectorVisible">{{ lang }}</span>
      </div>
    </section>

    <h1>Оплата счета НПД</h1>

    <section class="fns-pay-info">
      <div class="fns-info-item">
        <div class="fns-info-item__name">№ Счета</div>
        <div class="fns-info-item__value">№{{ invoiceNumber }}</div>
      </div>
      <div class="fns-info-item">
        <div class="fns-info-item__name">Дата счета</div>
        <div
          class="fns-info-item__value"
          v-html="
            $formatDate(date, {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            })
          "
        ></div>
      </div>
      <div class="fns-info-item">
        <div class="fns-info-item__name">Продавец</div>
        <div class="fns-info-item__value">
          {{ merchantName }}<br />
          ИНН {{ inn }}
        </div>
      </div>
      <div class="fns-info-item">
        <div class="fns-info-item__name">Товары, работы, услуги</div>
        <div class="fns-info-item__value">
          <ol>
            <!-- <li>Макет – 1 500,00 ₽</li> -->
            <li
              class="fns-info-item__li"
              v-for="p in saleInfo"
              v-bind:key="p.productName"
            >
              {{ p.productName }} – <money :value="p.cost" />
              <img src="~@/assets/img/mult.svg" />
              {{ p.quantity }}
            </li>
          </ol>
        </div>
      </div>
    </section>

    <section class="fns-pay-payment-warning" v-if="needsPaymentWarnings">
      <div class="fns-pay-contacts__desc">
        Самозанятый вправе продавать только товары и услуги собственного производства.
        <br />
        Перепродажа самозанятым товаров и услуг, приобретенных у третьих лиц, незаконна
        (п.2 ст.4 422-ФЗ от 27.11.2018)
      </div>
    </section>

    <section class="fns-pay-amount">
      <h2>Cумма к оплате</h2>
      <div class="fns-pay-amount__value"><money :value="totalAmount" /></div>
    </section>

    <section class="fns-pay-contacts">
      <div class="fns-pay-contacts__desc">
        Для получения квитанции о безналичном переводе укажите контактную информацию
      </div>
      <receipt-send
        :isValid="isValid"
        :phone="phone"
        :email="email"
        :sendType="sendType"
        :onPhoneChange="onPhoneChange"
        :onEmailChange="onEmailChange"
        :onSendTypeChange="onSendTypeChange"
      />
    </section>

    <section>
      <h2>Способ оплаты</h2>
      <div class="fns-pay-methods">
        <button
          v-if="isApplePayAvailable"
          class="fns-pay-method fns-pay-method_applepay"
          :class="{ 'fns-pay-method_selected': payMethod === 'applepay' }"
          @click="select('applepay')"
        >
          <img v-bind:src="require(`./img/applepay.svg`)" alt />
        </button>
        <button
          v-if="isGooglePayAvailable"
          class="fns-pay-method fns-pay-method_googlepay"
          :class="{ 'fns-pay-method_selected': payMethod === 'googlepay' }"
          @click="select('googlepay')"
        >
          <img v-bind:src="require(`./img/googlepay.svg`)" alt />
        </button>
        <!-- <applepay-button
          buttonstyle="white"
          type="plain"
          locale="ru-RU"
        ></applepay-button> -->
        <button
          v-if="isSbpPaymentAvailable"
          class="fns-pay-method fns-pay-method_sbp"
          :class="{ 'fns-pay-method_selected': payMethod === 'sbp' }"
          :disabled="payMethod === 'sbp'"
          @click="select('sbp')"
        >
          <img v-bind:src="require(`./img/sbp.svg`)" alt />
        </button>
        <button
          v-if="isCardPaymentAvailable"
          class="fns-pay-method fns-pay-method_card"
          :class="{ 'fns-pay-method_selected': payMethod === 'card' }"
          :disabled="payMethod === 'card'"
          @click="select('card')"
        >
          <img v-bind:src="require(`./img/card.svg`)" alt />
          <!-- <img
            v-if="payMethod !== 'card'"
            v-bind:src="require(`./img/card.svg`)"
            alt
          />
          <img
            v-if="payMethod === 'card'"
            v-bind:src="require(`./img/card_white.svg`)"
            alt
          /> -->
          <span class="fns-pay-method__text">По карте</span>
        </button>
      </div>
    </section>

    <section>
      <div
        id="payment-form"
        class="fns-payment-form"
        :class="{ 'fns-payment-form_hidden': !showCardForm }"
      ></div>
    </section>

    <section v-if="paymentDataCardUrl !== null && showCardForm">
      <iframe class="card-payment-frame" :src="paymentDataCardUrl"></iframe>
    </section>

    <section class="fns-sbp-qrcode" :class="{ 'fns-sbp-qrcode_visible': showSbpQrcode }">
      <canvas id="sbp-qrcode"></canvas>
      <div class="fns-sbp-qrcode__text">
        Перейдите в раздел переводов через СБП в мобильном приложении своего банка,
        сканируйте QR-код и оплатите. Или нажмите на кнопку для выбора банка для оплаты
      </div>
    </section>

    <section class="fns-pay-desc">
      Без комиссии. Безопасность каждого платежа обеспечена.
    </section>

    <section class="fns-pay-offer">
      <div><a href="http://qubiq.online/legal">Пользовательское соглашение</a></div>
      <div><a href="http://qubiq.online/legal">Политика конфиденциальности</a></div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

import { validateEmail, validatePhone } from '@/utils'
import ReceiptSend from '@/components/fns/ReceiptSend.vue'
import { isApplePayAvailable } from '@/utils/apple-pay'

export default {
  name: 'FnsPay',
  data() {
    return {
      isValid: true,
      phone: '',
      sendType: 'email',
      langSelectorVisible: false,
      lang: 'RU',
      langs: ['GR', 'FR', 'EN', 'RU'],
      payMethod: null,
    }
  },
  components: { ReceiptSend },
  computed: {
    ...mapState({
      saleInfo: (state) => state.pay.invoicePaymentData.saleInfo,
      totalAmount: (state) => state.pay.invoicePaymentData.totalAmount,
      merchantName: (state) => state.pay.invoicePaymentData.merchantName,
      invoiceNumber: (state) => state.pay.invoicePaymentData.invoiceNumber,
      inn: (state) => state.pay.invoicePaymentData.inn,
      date: (state) => state.pay.invoicePaymentData.date,
      email: (state) => state.pay.email,
      initPaymentData: (state) => state.pay.initPaymentData,
      paymentCardData: (state) => state.pay.paymentCardData,
      isReadyToGooglePay: (state) => state.pay.googlepayData.isReadyToPay,
      needsPaymentWarnings: (state) => state.pay.invoicePaymentData.acquirerAlias === 'MOBI-VTB',
    }),
    ...mapGetters({
      isCardPaymentAvailable: 'pay/isCardPaymentAvailable',
      isSbpPaymentAvailable: 'pay/isSbpPaymentAvailable',
      isApplepayPaymentAvailable: 'pay/isApplepayPaymentAvailable',
      isGooglePaymentAvailable: 'pay/isGooglepayPaymentAvailable',
      paymentDataCardUrl: 'pay/paymentDataCardUrl',
      isDesktop: 'pay/isDesktop',
      showSbpQrcode: 'pay/showSbpQrcode',
      showCardForm: 'pay/showCardForm',
    }),
    isApplePayAvailable() {
      return this.isApplepayPaymentAvailable && isApplePayAvailable()
    },
    isGooglePayAvailable() {
      return this.isGooglePaymentAvailable && this.isReadyToGooglePay
    },
    isPhoneValid() {
      return this.sendType === 'phone' && this.isValid
    },
    isEmailValid() {
      return this.sendType === 'email' && this.isValid
    },
  },
  methods: {
    ...mapActions({
      requestPaySbp: 'pay/requestPaySbp',
      requestPaymentCard: 'pay/requestPaymentCard',
      startApplePay: 'pay/startApplePay',
      startGooglePay: 'pay/startGooglePay',
      waitForIsReadyToGooglePay: 'pay/waitForIsReadyToGooglePay',
      closePaymentForms: 'pay/closePaymentForms',
    }),
    ...mapMutations({ setEmail: 'pay/setEmail', setSuccess: 'pay/setSuccess' }),
    selectLang: function (event) {
      const lang = event.target.value
      this.toggleLangs()
      this.lang = lang
    },
    toggleLangs: function () {
      this.langSelectorVisible = !this.langSelectorVisible
    },
    select: function (value) {
      if (!this.validate()) {
        return
      }
      this.closePaymentForms()
      this.payMethod = null
      this.payMethod = value
      switch (value) {
        case 'sbp': {
          this.requestPaySbp()
          break
        }
        case 'card': {
          this.requestPaymentCard({ status: 'NEW' })
          break
        }
        case 'applepay': {
          this.startApplePay()
          break
        }
        case 'googlepay': {
          this.startGooglePay()
          break
        }
      }
    },
    validate() {
      if (this.sendType === 'phone') {
        this.isValid = validatePhone('ru', this.phone)
      }
      if (this.sendType === 'email') {
        this.isValid = validateEmail(this.email)
      }
      return this.isValid
    },
    onSendTypeChange(value) {
      this.sendType = value
      this.isValid = true
    },
    onPhoneChange(value) {
      this.phone = value
      this.isValid = true
    },
    onEmailChange(value) {
      this.setEmail({ email: value })
      this.isValid = true
    },
  },
  mounted() {
    // YandexMetrika
    let ths = this
    function yandexMetrika() {
      let btnClasses = [
        '.fns-pay-method_applepay',
        '.fns-pay-method_googlepay',
        '.fns-pay-method_sbp',
        '.fns-pay-method_card',
      ]
      let btnTypes = ['button_applepay', 'button_googlepay', 'button_sbp', 'button_card']
      for (let i = 0; i < btnClasses.length; i++) {
        const element = document.querySelector(btnClasses[i])
        if (element == null) {
          continue
        } else {
          element.addEventListener('click', () => {
            ths.$metrika.reachGoal(btnTypes[i])
          })
        }
      }
    }

    yandexMetrika()
    // /YandexMetrika

    this.waitForIsReadyToGooglePay()
  },
}
</script>
<style scoped lang="scss">
button:disabled {
  opacity: 0.5;
}
</style>
