<template>
  <section>
    <template v-if="error === null && status !== 'SUCCESS' && status !== 'ERROR'">
      <preloader :isFetching="isLoading" />
      <BindCard />
    </template>

    <template v-if="error === null && status === 'SUCCESS'">
      <Success :message="'Карта успешно добавлена'" />
    </template>

    <template v-if="error === null && status === 'ERROR'">
      <Fail :message="'payment_error'" :desc="'?'" />
    </template>

    <template v-if="error !== null">
      <Fail :message="'Ошибка'" :desc="error" />
    </template>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import bind from '@/store/modules/bind'

import BindCard from './BindCard.vue'
import Success from './Success.vue'
import Fail from './Fail.vue'

const PAY2PRO_URL_PREFIX = 'https://pay2.pro'

export default {
  name: 'FnsBind',

  components: {
    BindCard,
    Success,
    Fail,
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters({
      status: 'bind/status',
      url: 'bind/url',
    }),

    ...mapState({
      isLoading: (state) => state.bind.isLoading,
      error: (state) => state.bind.error,
    }),
  },

  methods: {
    getPay2proTokenFromEvent(event) {
      if (
        event.data &&
        event.data.type &&
        event.data.type === 'card_token_event' &&
        event.data.token
      ) {
        return event.data.token
      }

      return undefined
    },

    onWindowMessage(event) {
      const pay2proToken = this.getPay2proTokenFromEvent(event)

      if (pay2proToken) {
        this.updateCardToken({ internalId: this.internalId, token: pay2proToken }).then(
          () => {
            this.requestCardBindStatus(this.internalId)
          },
        )
      }
    },

    ...mapActions({
      requestCardBind: 'bind/requestCardBind',
      requestCardBindStatus: 'bind/requestCardBindStatus',
      updateCardToken: 'bind/updateCardToken',
    }),
  },

  created() {
    this.internalId = this.$route.params.internalId

    this.$store.registerModule('bind', bind)
  },

  mounted: function () {
    this.requestCardBind(this.internalId).then(() => {
      if (this.url.startsWith(PAY2PRO_URL_PREFIX)) {
        window.addEventListener('message', this.onWindowMessage)
      } else {
        this.requestCardBindStatus(this.internalId)
      }
    })
  },

  beforeDestroy() {
    window.removeEventListener('message', this.onWindowMessage)
  },
}
</script>
