<template>
  <header v-if="headerBlock" class="header-block">
    <div class="header-block__logo">
      <img :src="headerLogoUrl.light" alt="" />
    </div>
    <div class="header-block__lang">
      <div v-if="langSelectorVisible" class="header-block__lang-selector">
        <div v-for="l in langs" :key="l">
          <label>
            <input :id="'lang-' + l" :value="l" @click="selectLang" name="lang" type="radio" />
          </label>
          <label :for="'lang-' + l">{{ l }}</label>
        </div>
      </div>
      <div @click="toggleLangs" class="header-block__selected-lang">
        <span v-if="!langSelectorVisible">{{ lang }}</span>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import header from '@/mixins/header'

export default {
  mixins: [header],
  computed: {
    ...mapGetters(['headerBlock', 'headerLogoUrl']),
  },
}
</script>

<style lang="scss">
@import '~@/assets/scss/components/header.scss';
</style>
