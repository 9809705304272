<template>
  <section class="fns-selfemployed">
    <h1 class="fns-selfemployed_title">
      Согласие на передачу <br />
      персональных данных
    </h1>
    <p class="fns-selfemployed_federal-law">
      Даю своё согласие <span>ООО «МКД»</span> на передачу моих персональных данных в
      соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных».
    </p>
    <p class="fns-selfemployed_purpose-consent">
      В целях заключения договора на условиях оферты с
      {{ config.name | strippedContent }}
      персональные данные:
    </p>
    <section class="fns-selfemployed__list-item">• фамилия, имя, отчество;</section>
    <section class="fns-selfemployed__list-item">• паспортные данные;</section>
    <section class="fns-selfemployed__list-item">• СНИЛС;</section>
    <section class="fns-selfemployed__list-item">• ИНН;</section>
    <section class="fns-selfemployed__list-item">• контактный номер телефона;</section>
    <p>передаются выбранному мною {{ config.name | strippedContent }}.</p>
    <p class="fns-selfemployed_period-consent">
      Настоящее согласие действует в течение всего периода хранения персональных данных,
      если иное не предусмотрено законодательством Российской Федерации.
    </p>
    <p class="fns-selfemployed_protocol">
      Персональные данные передаются в адрес
      {{ config.name | strippedContent }} по протоколу https.
    </p>
  </section>
</template>

<script>
import cfg from '@/config/selfemployed'

export default {
  name: 'TermsOfUse',
  computed: {
    config: function () {
      return cfg.find(
        (c) => c.alias.toLowerCase() === this.$route.params.alias.toLowerCase(),
      )
    },
  },
  filters: {
    strippedContent: function (string) {
      return string.replace(/<\/?[^>]+>/gi, ' ')
    },
  },
}
</script>

<style lang="scss" scoped>
.fns-selfemployed__list-item {
  text-align: left;
}
</style>
