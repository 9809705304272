import axios from 'axios'

const isDevelopment = process.env.NODE_ENV === 'development'

export const cfg = {
  baseURL: isDevelopment
    ? process.env.VUE_APP_FNS_API
    : `https://fns-service-api.${window.location.host}`,
  headers: {},
}

export type PaymentMethod = 'card' | 'sbp' | 'applepay' | 'googlepay'

export type AcquireAlias = 'MONETA' | 'PAY2PRO' | 'PAYLER' | 'UNLIMCO' | 'MONETA-NPD' | 'MOBI-VTB' | 'CKASSA' | 'B2P' 

type InvoiceSaleInfo = {
  productName: string
  quantity: number
  cost: number
}

export interface InvoicePaymentData {
  invoiceNumber: null | number | string
  totalAmount: number
  saleInfo: Record<string, unknown>[] | InvoiceSaleInfo[]
  internal_id: string
  status: null | string
  date: null | string
  acquirerAlias: null | AcquireAlias
  /**
   * applepay: внутри paymentRequest нужно заменить label на platformAlias
   */
  platformAlias: null | string
  listPaymentMethods: null | PaymentMethod[]
  merchantName: null | string
  inn: null | string
  signature: string
  error: string
}

export const invoicePaymentData = async (invoiceId: string | number) => {
  const { data } = await axios.get<InvoicePaymentData>(
    `/invoice/${invoiceId}/payment_data`,
    cfg,
  )

  return data
}

export interface MerchantPermissionData {
  result: 'success' | 'error'
}

export const merchantPermission = async ({
  permissionRequestId,
  result,
}: {
  permissionRequestId: string
  result: 'success' | 'error'
}) => {
  await axios.post<MerchantPermissionData>(
    `/merchant/acquirer/permission/${permissionRequestId}`,
    { result },
    cfg,
  )
  return true
}

export interface ApplepayNewData {
  session: any
  payment_internal_id: string
}

export interface ApplepayProgressData {
  status: 'SUCCESS' | 'ERROR'
  payment_internal_id: string
}

export const paymentApplepay = async ({
  amount,
  internalInvoiceId,
  paymentInternalId,
  paymentExternalId,
  status,
  email,
  phone,
  signature,
  datagram,
  validationURL,
}: {
  amount: number
  internalInvoiceId: string
  status: 'NEW' | 'PROGRESS'
  paymentInternalId: null | string
  paymentExternalId: null | number
  email: any
  phone: any
  signature: string
  datagram?: string
  validationURL?: string
}) => {
  const data: {
    amount: number
    internal_invoice_id: string
    status: 'NEW' | 'PROGRESS'
    payment_internal_id: null | string
    payment_external_id: null | number
    email: any
    phone: any
    signature: string
    datagram?: string
    validation_url?: string
  } = {
    amount,
    internal_invoice_id: internalInvoiceId,
    status,
    payment_internal_id: paymentInternalId,
    payment_external_id: paymentExternalId,
    email,
    phone,
    signature,
  }
  if (datagram) {
    data.datagram = datagram
  }
  if (validationURL) {
    data.validation_url = validationURL
  }
  const result = await axios.post<ApplepayNewData | ApplepayProgressData>(
    '/payment/applepay',
    data,
    cfg,
  )
  return result.data
}

export interface GooglepayNewData {
  payment_internal_id: string
}

export interface GooglepayProgressData {
  status: 'SUCCESS' | 'ERROR'
  payment_internal_id: string
}

export const paymentGooglepay = async ({
  amount,
  internalInvoiceId,
  paymentInternalId,
  paymentExternalId,
  status,
  email,
  phone,
  signature,
  datagram,
}: {
  amount: number
  internalInvoiceId: string
  status: 'NEW' | 'PROGRESS'
  paymentInternalId: null | string
  paymentExternalId: null | number
  email: any
  phone: any
  signature: string
  datagram?: string
}) => {
  const data: {
    amount: number
    internal_invoice_id: string
    status: 'NEW' | 'PROGRESS'
    payment_internal_id: null | string
    payment_external_id: null | number
    email: string
    phone: any
    signature: string
    datagram?: string
  } = {
    amount,
    internal_invoice_id: internalInvoiceId,
    status,
    payment_internal_id: paymentInternalId,
    payment_external_id: paymentExternalId,
    email,
    phone,
    signature,
  }
  if (datagram) {
    data.datagram = datagram
  }
  const result = await axios.post<GooglepayNewData | GooglepayProgressData>(
    '/payment/googlepay',
    data,
    cfg,
  )
  return result.data
}

export interface InitPaymentData {
  account: number
}

export const paymentInit = async ({
  internalInvoiceId,
  signature,
}: {
  internalInvoiceId: string
  signature: string
}) => {
  const data = {
    internal_invoice_id: internalInvoiceId,
    signature,
  }
  const result = await axios.post<InitPaymentData>('/payment/init ', data, cfg)
  return result.data
}

export interface PaymentSbpData {
  payment_internal_id: string
  sbp_url: string
}

export const paymentSpb = async ({
  amount,
  internalInvoiceId,
  email,
  phone,
  signature,
}: {
  amount: number
  internalInvoiceId: string
  email: any
  phone: any
  signature: string
}) => {
  const data = {
    amount,
    internal_invoice_id: internalInvoiceId,
    email,
    phone,
    status: 'NEW',
    signature,
  }
  const result = await axios.post<PaymentSbpData>('/payment/sbp', data, cfg)
  return result.data
}

export interface PaymentCardData {
  amount: number
  invoice_internal_id: string
  email: any
  phone: any
  payment_internal_id: string
  payment_external_id: string
  operation_id?: number
  status: string
  paymentUrl?: string
}

export const paymentCard = async ({
  amount,
  invoiceInternalId,
  operationId,
  paymentExternalId,
  paymentInternalId,
  email,
  phone,
  status,
  signature,
}: {
  amount: number
  invoiceInternalId: string
  operationId?: number
  paymentInternalId?: string
  paymentExternalId?: string
  email: any
  phone: any
  status: string
  signature: string
}) => {
  const data: {
    amount: number
    invoice_internal_id: string
    operation_id?: number
    email: any
    phone: any
    payment_internal_id?: string
    payment_external_id?: string
    status: string
    signature: string
  } = {
    amount,
    invoice_internal_id: invoiceInternalId,
    email,
    phone,
    status,
    signature,
  }
  if (operationId) {
    data.operation_id = operationId
  }
  if (paymentExternalId) {
    data.payment_internal_id = paymentExternalId
  }
  // NEW status cannot be sent along with payment_internal_id,
  // NEW always mean new payment
  if (paymentInternalId && status !== 'NEW') {
    data.payment_internal_id = paymentInternalId
  }
  const result = await axios.post<PaymentCardData>('/payment/card', data, cfg)
  if (isDevelopment) {
    console.debug(`/payment/card: ${result}`)
  }
  return result.data
}

export interface CardBindData {
  bind_url: string
}

export const requestCardBind = async (internalId: string): Promise<CardBindData> => {
  const { data } = await axios.post<CardBindData>(
    '/payout/card/bind',
    { internal_id: internalId },
    cfg,
  )
  return data
}

export const updateCardToken = async (
  internalId: string,
  token: string,
): Promise<void> => {
  await axios.post<void>(
    `/payout/card/bind/${internalId}/token`,
    { internal_id: internalId, token },
    cfg,
  )
}

export interface CardBindStatusData {
  merchant_id: string
  acquirer_id: string
  status: 'SUCCESS' | 'ERROR' | string
}

export const requestCardBindStatus = async (
  internalId: string,
): Promise<CardBindStatusData> => {
  const { data } = await axios.get<CardBindStatusData>(
    `/payout/card/bind/${internalId}`,
    cfg,
  )
  return data
}

export const retryInvoice = (invoiceInternalId: string): Promise<void> => {
  return axios.post(`/invoice/${invoiceInternalId}/retry`, null, {
    ...cfg,
  })
}
