import { Block } from '@/constants'

export default {
  state: () => ({}),
  mutations: {},
  actions: {},
  getters: {
    merchantBlock(state, getters, rootState) {
      return rootState.page?.getBlock(Block.MerchantBlock)
    },
    merchantLogoUrl(state, getters) {
      return getters.merchantBlock?.body.find((b) => b.name === 'logo_url').value || null
    },
    merchantName(state, getters) {
      return getters.merchantBlock?.body.find((b) => b.name === 'name').value || null
    },
  },
}
