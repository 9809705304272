<template>
  <section>
    <main v-if="error === null" class="fns-selfemployed">
      <article>
        <section v-if="name !== null" class="fns-selfemployed-head">
          <div class="fns-selfemployed-head__logo">
            <img :src="logo" />
          </div>
          <div class="fns-selfemployed-head__name" v-html="name"></div>
        </section>
        <h1>Соглашение на обработку <br />персональных данных</h1>
        <section class="fns-selfemployed__list-title">
          Для подключения необходимо Ваше согласие на обработку следующих персональных
          данных:
        </section>
        <section class="fns-selfemployed__list-item">• ФИО</section>
        <section class="fns-selfemployed__list-item">• Полные паспортные данные</section>
        <section class="fns-selfemployed__list-item">• ИНН</section>
        <section class="fns-selfemployed__list-item">• СНИЛС</section>
        <section class="fns-selfemployed__list-item">• Телефон</section>
        <section class="fns-selfemployed_confirm">
          <input
            v-model="cofirmed"
            type="checkbox"
            value="primary"
            role="checkbox"
            id="fns-selfemployed_confirm_checkbox"
            class="fns-selfemployed_confirm_checkbox"
          />
          <label
            for="fns-selfemployed_confirm_checkbox"
            class="fns-selfemployed_confirm_label"
          >
            <div style="color: #172331; font-size: 15px; width: 235px">
              Подтвердите
              <a target="_blank" @click.prevent="onTermsOfUseClick">
                согласие на <br />передачу и обработку данных
              </a>
            </div>
          </label>
        </section>
        <section>
          <v-btn
            block
            elevation="2"
            large
            color="primary"
            @click="confirm"
            :id="!cofirmed ? 'confirm-btn-disable' : 'ddd'"
            :disabled="!cofirmed"
            >Подтвердить</v-btn
          >
        </section>
        <section>
          <v-btn id="reject-btn" block elevation="2" large outlined @click="reject"
            >Отклонить</v-btn
          >
        </section>
      </article>
    </main>
    <template v-if="error !== null">
      <Fail
        :message="'Мы не смогли вас зарегистрировать, попробуйте еще раз'"
        :desc="error"
        :text="'Попробовать снова'"
      />
    </template>
  </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'

import Fail from './Fail.vue'
import { parseToken } from '@/utils/fns'
import cfg from '@/config/selfemployed'

export default {
  components: { Fail },
  name: 'FnsSelfemployedConfirm',
  data: function () {
    return {
      cofirmed: false,
      name: null,
      logo: null,
    }
  },

  computed: {
    ...mapState({ error: (state) => state.pay.error }),
  },

  methods: {
    ...mapActions({
      requestMerchantPermission: 'pay/requestMerchantPermission',
    }),
    ...mapMutations({ setConfirmData: 'pay/setConfirmData' }),
    confirm() {
      this.requestMerchantPermission({
        result: 'success',
      })
    },
    reject() {
      this.requestMerchantPermission({
        result: 'error',
      })
    },
    onTermsOfUseClick() {
      const goTo = this.$router.resolve({
        name: 'terms-of-use',
        params: { alias: this.alias },
      })
      window.open(goTo.href, '_blank')
    },
  },

  mounted() {
    const token = this.$route.params.token
    const parsed = parseToken(token)
    if (parsed === null) {
      this.$router.replace('/fns/selfemployed/confirm')
      return
    }
    const cfgVal = cfg.find(
      (c) => c.alias.toLowerCase() === parsed.acquirer_alias.toLowerCase(),
    )
    if (!cfgVal) {
      console.error(`Could not find configuration for ${parsed.acquirer_alias}`)
      this.$router.replace('/fns/selfemployed/confirm')
      return
    }
    this.alias = cfgVal.alias
    this.name = cfgVal.name
    this.logo = require(`../../assets/img/${cfgVal.logo}`)
    this.setConfirmData(parsed)
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/fns/variables';
// @import "@/assets/fonts/PFDinTextCondPro";
@font-face {
  font-family: 'PFDinTextCondPro';
  src: url('~@/assets/fonts/PFDinTextCondPro/PFDinTextCondPro-Regular.ttf');
  src: local('PFDinTextCondPro-Regular'), local('PF Din Text Cond Pro Regular'),
    url('~@/assets/fonts/PFDinTextCondPro/PFDinTextCondPro-Regular.ttf')
      format('truetype');
  font-weight: 500;
  font-style: normal;
}
#confirm-btn-disable {
  background: linear-gradient(180deg, #0066b3 0%, #0078d3 100%);
  opacity: 0.3;
  color: white !important;
}

#reject-btn {
  border: 2px solid #0066b3;
  color: #0066b3;
  background: white;
}

.fns-selfemployed {
  min-height: 100%;
  box-sizing: border-box;
  padding: 10px 15px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 21px;
  font-weight: normal;
  color: $color;
  background-color: $bg-color;
  &_confirm {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    &_checkbox {
      display: none;
    }
    &_checkbox:checked + &_label::after {
      transform: scale(1);
    }
    &_label {
      position: relative;
      align-items: center;
      display: inline-flex;
      cursor: pointer;
      & div {
        text-align: left;
      }
      &::before {
        content: ' ';
        flex: 0 0 24px;
        height: 24px;
        background-color: #ffffff;
        border-radius: 4px;
        border: 2px solid #dedede;
        margin: 0 16px 0 0;
      }
      &::after {
        content: url(./img/confirm_checkbox.svg);
        width: 22px;
        height: 16px;
        position: absolute;
        top: 9px;
        left: 4px;
        transform: scale(0);
        // background-color: #0066b3;
      }
    }
  }

  h1 {
    margin-bottom: 21px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }

  section {
    margin-bottom: 21px;
    text-align: center;
  }

  &__list-item {
    font-size: 18px;
    font-weight: 500;
    font-weight: 500;
  }

  &__confirm {
    margin: 0 40px;
  }
}

.fns-selfemployed-head {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  &__logo {
    margin: 14px 0;
    width: 104px;
    height: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
    // border-radius: 10px;
    // border: 1px solid #dedede;
    // background-color: #fff;

    > img {
      max-width: 100%;
    }
  }

  &__name {
    font-family: 'PFDinTextCondPro';
    font-weight: 600;
    font-size: 22px;
    line-height: 20px;
  }
}
</style>
