<template>
  <app-wrap>
    <section style="font-size: 0.8em; padding: 10px; background-color: #f7f7f7">
      <h1>Страницы ФНС</h1>
      <ul>
        <li>
          <router-link to="/pay/86102449-9d12-4e59-8900-fa45154afe95"
            >ФНС оплата</router-link
          >
        </li>
        <li>
          <router-link to="/success">ФНС успех</router-link>
        </li>
        <li>
          <router-link to="/fail">ФНС неудача</router-link>
        </li>
        <li>
          <router-link
            to="/selfemployed/confirm/eyJtaWQiOiI3MGVhNzcwMC1iZjQwLTQ4MzMtODU2ZC05ZDJjZmZjNmMwZTkiLCJhdXJsIjoiaHR0cDovL25hbG9nLmFwcC5ydS9vayIsInJ1cmwiOiJodHRwOi8vbmFsb2cuYXBwLnJ1L25vdF9vayIsInByaWQiOiJmYTVhN2UzNS0zNGViLTQxMTctYmQ0My02ZjM2MGE1ZDVjMzUiLCJhYSI6Ik1PTkVUQSJ9"
            >Самозанятый - подтверждение</router-link
          >
        </li>
      </ul>
    </section>
  </app-wrap>
</template>

<script>
import AppWrap from '@/components/AppWrap.vue'
export default {
  components: { AppWrap },
}
</script>
