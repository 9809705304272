<template>
  <section>
    <template v-if="error === null && (status === 'processing' || status === 'payment')">
      <preloader v-if="status === 'payment'" :isFetching="true" />
      <Pay />
    </template>

    <template v-if="error === null && status === 'success'">
      <Success />
    </template>

    <template v-if="error === null && status === 'payment_error'">
      <Fail :message="'Ошибка. Попробуйте снова'" :desc="error" />
    </template>

    <template v-if="error !== null">
      <Fail :message="'Ошибка'" :desc="error" />
    </template>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import Pay from './Pay.vue'
import Success from './Success.vue'
import Fail from './Fail.vue'

export default {
  name: 'FnsPay',
  components: {
    Pay,
    Success,
    Fail,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.pay.isLoading,
      status: (state) => state.pay.invoicePaymentData.status,
      error: (state) => state.pay.error,
    }),
  },
  methods: {
    ...mapActions({
      requestInvoicePaymentData: 'pay/requestInvoicePaymentData',
    }),
  },
  mounted: function () {
    const invoiceId = this.$route.params.invoiceId

    this.requestInvoicePaymentData({ invoiceId })
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

@import './styles.scss';
</style>
