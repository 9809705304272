<template>
  <div class="fns-receipt-send">
    <div class="fns-send-type">
      <div
        class="fns-send-type-item"
        :class="{ 'fns-send-type-item_active': sendType === 'email' }"
      >
        <input
          id="email"
          type="radio"
          name="receipt_send_type"
          value="email"
          :checked="sendType === 'email'"
          @click="sendTypeChange"
        />
        <label for="email">E-mail</label>
      </div>
      <!-- <div
        class="fns-send-type-item"
        :class="{ 'fns-send-type-item_active': sendType === 'phone' }"
      >
        <input
          id="phone"
          type="radio"
          name="receipt_send_type"
          value="phone"
          :checked="sendType === 'phone'"
          @click="sendTypeChange"
        />
        <label for="phone">Телефон</label>
      </div> -->
    </div>

    <div v-show="sendType == 'phone'">
      <div
        class="fns-text-input fns-text-input_phone"
        :class="{ ['fns-text-input_invalid']: !this.isValid }"
      >
        <masked-input
          ref="phone"
          type="text"
          :mask="mask"
          :guide="false"
          placeholder="+7 (000) 000-00-00"
          placeholderChar="_"
          :value="phone"
          :pipe="phonePipe"
          @keyup="phoneChange"
          :style="stylePhoneObject"
        >
        </masked-input>
      </div>
    </div>
    <div v-show="sendType == 'email'">
      <div
        class="fns-text-input fns-text-input_email"
        :class="{ ['fns-text-input_invalid']: !this.isValid }"
      >
        <input
          type="text"
          ref="email"
          placeholder="Введите e-mail"
          :value="email"
          @input="emailChange"
          :style="styleEmailObject"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { masks } from '@/constants'
// https://github.com/text-mask/text-mask
// https://github.com/text-mask/text-mask/tree/master/vue#readme
import MaskedInput from 'vue-text-mask'

function getWidthOfInput(value) {
  const tmp = document.createElement('span')
  tmp.className = 'fns-tmp-element'
  tmp.innerHTML = value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
  document.body.appendChild(tmp)
  // const tmpWidth = tmp.getBoundingClientRect().width;
  const tmpWidth = tmp.offsetWidth + 10 // 10 из-за странного поведения на ios
  document.body.removeChild(tmp)
  return tmpWidth
}

export default {
  components: { MaskedInput },
  props: [
    'isValid',
    'phone',
    'email',
    'sendType',
    'onSendTypeChange',
    'onPhoneChange',
    'onEmailChange',
    'setFocus',
  ],
  data() {
    return {
      mask: masks.phone.ru,
    }
  },
  computed: {
    stylePhoneObject: function () {
      return {
        width: getWidthOfInput(this.phnoe || '+7 (000) 000-00-00') + 'px',
      }
    },
    styleEmailObject: function () {
      return { width: getWidthOfInput(this.email || 'Введите e-mail') + 'px' }
    },
  },
  name: 'ReceiptSend',
  methods: {
    phoneChange() {
      const value = this.$refs.phone.$el.value
      this.onPhoneChange(value)
    },
    emailChange(e) {
      this.onEmailChange(e.target.value)
    },
    phonePipe(conformedValue) {
      if (conformedValue === '+7 (8') {
        return '+7 ('
      }
      return conformedValue
    },
    sendTypeChange(e) {
      this.onSendTypeChange(e.target.value)
    },
  },
  mounted() {
    const inputs = document.querySelectorAll('.fns-text-input input')

    for (let input of inputs) {
      if (input.parentNode.tagName.toString().toLowerCase() == 'div') {
        input.onfocus = function () {
          this.parentNode.style.borderStyle = 'solid'
        }
        input.onblur = function () {
          this.parentNode.style.borderStyle = 'none'
        }
      }
    }
  },
  updated: function () {
    if (this.isValid) {
      return
    }
    let inp
    switch (this.sendType) {
      case 'phone': {
        inp = this.$refs[this.sendType].$el
        break
      }
      case 'email': {
        inp = this.$refs[this.sendType]
        break
      }
      default: {
        throw new Error(`Uknkown send type: ${this.sendType}`)
      }
    }
    inp.focus()
  },
}
</script>

<style lang="scss">
$color: #838a99;
$color-active: #0066b3;

.fns-tmp-element {
  visibility: hidden;
  font-size: 18px;
  line-height: 32px;
  white-space: pre;
}

.fns-text-input {
  height: 52px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: none 2px #cbe2f3;

  &_invalid {
    border: solid 2px #f3cbcb !important;
  }

  > input {
    border: none;
    outline: none;
    font-size: 18px;
    line-height: 32px;
  }
}

.fns-receipt-send {
  .field__input_text {
    font-size: 21px;
    // line-height: 25px;
    text-align: left;
    padding: 0 16px;
  }
}

.fns-send-type {
  margin-bottom: 32px;
  font-weight: normal;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  border-bottom: solid 1px rgba(131, 138, 153, 0.2);
}

.fns-send-type-item {
  padding: 0 32px;
  color: $color;
  font-size: 20px;
  line-height: 32px;

  &_active {
    color: $color-active;
    border-bottom: solid 2px;
  }

  input[type='radio'] {
    display: none;
  }
}
</style>
