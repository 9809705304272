<template>
  <main class="fns-pay-result">
    <div class="fns-pay-result__result">
      <div class="fns-pay-result__result-wrap">
        <div class="fns-pay-result__icon fns-pay-result__icon_fail"></div>
        <!-- <div class="fns-pay-result__text">Платеж отклонен</div>
        <div class="fns-pay-result__sub-text">Недотаточно средст на карте</div> -->
        <div class="fns-pay-result__text">{{ message }}</div>
        <div class="fns-pay-result__sub-text" v-html="desc"></div>
      </div>
    </div>

    <div class="fns-pay-result__button">
      <v-btn block elevation="2" large color="primary" @click="retry">{{
        text || 'Вернуться к оплате'
      }}</v-btn>
    </div>
  </main>
</template>

<script>
import { retryInvoice } from '@/api/fns'

export default {
  props: ['message', 'desc', 'text'],
  name: 'FnsFail',

  methods: {
    retry() {
      const invoiceInternalId = this.$route.params.invoiceId

      // github.com/vuejs/vue-router/issues/2554
      // this.$router.go();
      retryInvoice(invoiceInternalId).finally(() => window.location.reload())
    },
  },
}
</script>
