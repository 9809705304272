<template>
  <div class="app-wrap">
    <div class="app-wrap__inner">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DemoWrap',
}
</script>

<style lang="scss">
.app-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  height: 100%;

  &__inner {
    width: 100%;
    height: 100%;

    @media (min-width: 768px) {
      min-width: 320px;
      max-width: 414px;
    }

    & > section {
      height: 100%;
    }
  }
}
</style>
