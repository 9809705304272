import axios, { AxiosError } from 'axios'
import { ActionContext } from 'vuex'

import {
  AcquireAlias,
  CardBindData,
  CardBindStatusData,
  requestCardBind as apiRequestCardBind,
  requestCardBindStatus as apiRequestCardBindStatus,
  updateCardToken as apiUpdateCardToken,
} from '@/api/fns'
import { processAxiosError } from '@/utils/api'

const STATUS_CHECK_INTERVAL = 1_000

interface State {
  cardBindData: null | CardBindData
  cardBindStatusData: null | CardBindStatusData
  error: null | string
  isLoading: boolean
}

const patchBindUrl = (url: string | undefined) => {
  if (url && url.startsWith('https://') && url.includes('unlimco')) {
    // Force compact (mobile) card details form
    return `${url}/mobile`
  }

  return url || null
}

export default {
  namespaced: true,

  state: <State>{
    cardBindData: null,
    cardBindStatusData: null,
    error: null,
    isLoading: true,
  },

  getters: {
    url: (state: State) => {
      return patchBindUrl(state.cardBindData?.bind_url)
    },

    status: (state: State) => {
      const status = state.cardBindStatusData?.status || null

      if (status === null) {
        return null
      }

      return status.toUpperCase()
    },
  },

  actions: {
    async requestCardBind(
      { commit, dispatch }: ActionContext<State, any>,
      internalId: string,
    ) {
      try {
        const cardBindData = await apiRequestCardBind(internalId)

        commit('receiveCardBind', cardBindData)
      } catch (error) {
        commit('setError', error)
      }
    },

    async requestCardBindStatus(
      { commit, dispatch }: ActionContext<State, any>,
      internalId: string,
    ) {
      try {
        const cardBindStatusData = await apiRequestCardBindStatus(internalId)

        commit('receiveCardBindStatus', cardBindStatusData)

        if (['SUCCESS', 'ERROR'].includes(cardBindStatusData.status.toUpperCase())) {
          return
        }

        setTimeout(() => {
          dispatch('requestCardBindStatus', internalId)
        }, STATUS_CHECK_INTERVAL)
      } catch (error) {
        commit('setError', error)
      }
    },

    async updateCardToken(
      { commit }: ActionContext<State, any>,
      {
        internalId,
        token,
      }: {
        internalId: string
        token: string
      },
    ) {
      try {
        await apiUpdateCardToken(internalId, token)
      } catch (error) {
        commit('setError', error)
      }
    },
  },

  mutations: {
    receiveCardBind(state: State, cardBindData: CardBindData) {
      state.cardBindData = cardBindData
      state.isLoading = false
    },

    receiveCardBindStatus(state: State, cardBindStatusData: CardBindStatusData) {
      state.cardBindStatusData = cardBindStatusData
    },

    setError(state: State, error: Error | AxiosError) {
      let err = ''
      if (axios.isAxiosError(error)) {
        err = processAxiosError(error)
      } else {
        err = error.message
      }

      console.error(err)

      state.error = err
      state.isLoading = false
    },
  },
}
