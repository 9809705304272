import { ActionContext } from 'vuex'
import { UAParser } from 'ua-parser-js'
import QRCode from 'qrcode'
import invariant from 'tiny-invariant'

import { REQUEST_ORDER } from '@/store/actions.type'
import {
  invoicePaymentData,
  paymentInit,
  paymentCard,
  paymentApplepay,
  paymentSpb,
  merchantPermission,
  InvoicePaymentData,
  PaymentCardData,
  PaymentSbpData,
  InitPaymentData,
  ApplepayNewData,
  ApplepayProgressData,
  paymentGooglepay,
  GooglepayNewData,
  GooglepayProgressData,
  AcquireAlias,
} from '@/api/fns'
import { startApplePay } from '@/utils/apple-pay'
import { startGooglePay } from '@/utils/google-pay'
import {
  getIsReadyToGooglePay,
  // prefetchGooglePaymentData,
} from '@/utils/google-pay'
import { sendOrder } from '@/api/template'
import axios, { AxiosError } from 'axios'
import { processAxiosError } from '@/utils/api'
import router from '@/router/fns'

const isDevelopment = process.env.NODE_ENV === 'development'

const googlepayMerchantName = 'QUBIQ'
const googlepayMerchantId = 'BCR2DN6TZ6EJVGCK'

const INVOICE_STATUS_CHECK_INTERNAL = 1_000
const INVOICE_STATUS_CHECK_POST_PAYMENT_DELAY = 5_000

const DEFAULT_ERROR_MESSAGE = 'Не удалось выполнить платеж'
const PAYMENT_ERRORS = {
  TIMEOUT: 'timeout',
}
const PAYMENT_ERROR_MESSSAGES = {
  [PAYMENT_ERRORS.TIMEOUT]: 'Срок оплаты истек. Попробуйте снова',
}

const EMPTY_PAYMENT_CARD_DATA = {
  amount: 0,
  invoice_internal_id: '',
  merchant_internal_id: '',
  email: '',
  phone: '',
  payment_internal_id: '',
  payment_external_id: '',
  operation_id: undefined,
  status: '',
}

const gateways: Record<AcquireAlias, string | undefined> = {
  MONETA: 'moneta',
  PAY2PRO: undefined,
  PAYLER: 'payler',
  UNLIMCO: undefined,
  'MONETA-NPD': undefined,
  'MOBI-VTB': undefined,
  CKASSA: undefined,
  B2P: undefined,  
} as const

export interface State {
  showSbpQrcode: boolean
  showCardForm: boolean
  invoicePaymentData: InvoicePaymentData
  initPaymentData: {
    account: number
  }
  confirmData: {
    acquirer_alias: null | string
    permission_request_id: null | string
    merchantId: null | string
    acceptedReturnUrl: null | string
    refusedReturnUrl: null | string
  }
  applepayData: {
    payment_internal_id: null | ApplepayNewData['payment_internal_id']
  }
  googlepayData: {
    isReadyToPay: boolean
    payment_internal_id: null | GooglepayNewData['payment_internal_id']
  }
  sbpData: {
    payment_internal_id: null | string
    sbp_url: null | string
  }
  paymentCardData: PaymentCardData
  email: string
  phone: string
  sendType: string
  error: null | string
}

const patchPaymentUrl = (url: string | undefined, acquirerAlias: AcquireAlias | null) => {
  if (url?.startsWith('https://') && acquirerAlias === 'UNLIMCO') {
    // Force compact (mobile) card details form
    return `${url}/mobile`
  }

  return url || null
}

export default {
  namespaced: true,
  state: <State>{
    showSbpQrcode: false,
    showCardForm: false,
    invoicePaymentData: {
      invoiceNumber: 0,
      totalAmount: 0,
      saleInfo: [],
      internal_id: '',
      status: null,
      date: null,
      acquirerAlias: null,
      platformAlias: null,
      listPaymentMethods: null,
      merchantName: null,
      inn: null,
      signature: '',
      error: '',
    },
    initPaymentData: {
      account: 0,
    },
    paymentCardData: EMPTY_PAYMENT_CARD_DATA,
    applepayData: {
      payment_internal_id: null,
    },
    googlepayData: {
      isReadyToPay: false,
      payment_internal_id: null,
    },
    sbpData: {
      payment_internal_id: null,
      sbp_url: null,
    },
    merchantPermissionData: {
      result: null,
    },
    confirmData: {
      acquirer_alias: null,
      permission_request_id: null,
      merchantId: null,
      acceptedReturnUrl: null,
      refusedReturnUrl: null,
    },
    email: isDevelopment ? 'aa@bb.cc' : null,
    phone: isDevelopment ? '+7 (999) 999-99-99' : null,
    sendType: 'email',
    error: null,
  },
  getters: {
    payResultData: (state: State) => state.invoicePaymentData,
    paymentSuccess: (state: State) => state.invoicePaymentData.status === 'success',
    isCardPaymentAvailable: (state: State) =>
      !Array.isArray(state.invoicePaymentData.listPaymentMethods) ||
      state.invoicePaymentData.listPaymentMethods.includes('card'),
    isSbpPaymentAvailable: (state: State) =>
      !Array.isArray(state.invoicePaymentData.listPaymentMethods) ||
      state.invoicePaymentData.listPaymentMethods.includes('sbp'),
    isApplepayPaymentAvailable: (state: State) =>
      !Array.isArray(state.invoicePaymentData.listPaymentMethods) ||
      state.invoicePaymentData.listPaymentMethods.includes('applepay'),
    isGooglepayPaymentAvailable: (state: State) =>
      !Array.isArray(state.invoicePaymentData.listPaymentMethods) ||
      state.invoicePaymentData.listPaymentMethods.includes('googlepay'),
    /**
     * pay2pro, payler, moneta-npd
     */
    paymentDataCardUrl: (state: State) => {
      return patchPaymentUrl(
        state.paymentCardData?.paymentUrl,
        state.invoicePaymentData.acquirerAlias,
      )
    },
    isDesktop: () => {
      return !new UAParser().getDevice().type
    },
    showSbpQrcode: (state: State) => state.showSbpQrcode,
    showCardForm: (state: State) => state.showCardForm,
  },
  actions: {
    async closePaymentForms({ commit }: ActionContext<State, any>) {
      commit('closePaymentForms')
    },
    async requestInvoicePaymentData(
      { commit, dispatch }: ActionContext<State, any>,
      { invoiceId }: { invoiceId: string },
    ) {
      commit('setLoading', true, { root: true })
      try {
        const paymentData = await invoicePaymentData(invoiceId)
        commit('receiveInvoicePaymentData', paymentData)
        dispatch('requestInitPayment')
        // dispatch("prefetchGooglePay");
        if (paymentData.status === 'payment') {
          setTimeout(() => {
            dispatch('checkInvoiceStatus', { invoiceId })
          }, INVOICE_STATUS_CHECK_INTERNAL)
        }

        if (paymentData.status === 'payment_error') {
          commit('setError', { error: new Error(paymentData.error) })
        }
      } catch (error) {
        commit('setError', { error })
        commit('setLoading', false, { root: true })
      }
    },

    async [REQUEST_ORDER](
      { commit, dispatch }: ActionContext<State, any>,
      { cartBlock, payMethod }: { cartBlock: object; payMethod: string },
    ) {
      const data = await sendOrder({ cartBlock, payMethod })
      // const data = await require("@/api/stub/tickets-success.json");
      // const data = await require("@/api/stub/all_not_available.json");
      // const data = await require("@/api/stub/item_not_available.json");
      commit('receive', data)
      dispatch('requestInitPayment')
      if (data.status === 'processing') {
        switch (payMethod) {
          case 'apay': {
            await dispatch('startApplePay')
            break
          }
          case 'gpay': {
            await dispatch('startGooglePay')
            break
          }
          case 'sbp': {
            await dispatch('requestPaySbp')
            break
          }
          case 'card': {
            await dispatch('requestPaymentCard', { status: 'NEW' })
            break
          }
        }
      } else if (data.status === 'error') {
        throw data.error
      }
    },

    async getInvoice({ commit }: ActionContext<State, any>) {
      const { data } = await axios.post('')
    },

    async checkInvoiceStatus(
      { commit, dispatch, state }: ActionContext<State, any>,
      { invoiceId }: { invoiceId: string },
    ) {
      try {
        const { status, error } = await invoicePaymentData(invoiceId)
        // const { status, error } = await require('@/api/stub/payment_data_success.json')

        if (status === 'success') {
          commit('setSuccess')
          return
        }

        if (status === 'payment_error') {
          commit('setError', { error: new Error(error) })
          return
        }

        setTimeout(() => {
          dispatch('checkInvoiceStatus', { invoiceId })
        }, INVOICE_STATUS_CHECK_INTERNAL)
      } catch (error) {
        console.error(error)
      }
    },

    async requestMerchantPermission(
      { commit, state }: ActionContext<State, any>,
      { result }: { result: 'success' | 'error' },
    ) {
      commit('setFetching', true, { root: true })
      try {
        const permissionRequestId = state.confirmData.permission_request_id as string
        await merchantPermission({ permissionRequestId, result })
        // CKASSA fires successful redirect from extra page
        const acquirerAlias = state.confirmData.acquirer_alias
        if (acquirerAlias === 'CKASSA') {
          router.push({
            name: 'post-confirm',
            params: { alias: acquirerAlias },
          })
        } else {
          commit('receiveMerchantPermission', { result })
        }
      } catch (error) {
        commit('setError', { error })
      } finally {
        commit('setFetching', false, { root: true })
      }
    },

    async requestPaySbp({ commit, dispatch, state, getters }: ActionContext<State, any>) {
      commit('setFetching', true, { root: true })
      try {
        // пока оставляю для отладки

        // commit("receivePaySbp", {
        //   sbpData: { sbp_url: "https://yandex.ru" },
        //   getters,
        // });
        const sbpData = await paymentSpb({
          amount: state.invoicePaymentData.totalAmount,
          internalInvoiceId: state.invoicePaymentData.internal_id,
          email: state.email,
          phone: state.phone,
          signature: state.invoicePaymentData.signature,
        })

        if (!sbpData.sbp_url) {
          throw new Error('sbp_url is undefined')
        }
        commit('receivePaySbp', { sbpData, getters })
        dispatch('checkInvoiceStatus', {
          invoiceId: state.invoicePaymentData.internal_id,
        })
      } catch (error) {
        commit('setError', { error })
      } finally {
        commit('setFetching', false, { root: true })
      }
    },

    // async requestPaySbp({ commit }) {
    //   commit("receivePaySbp", {
    //     link: "https://qr.nspk.ru/AD10004K20UDNBIM9FIBMQL690Q4FJK6?type=02&bank=100000000061&sum=1000&cur=RUB&crc=E387",
    //   });
    // },

    async requestInitPayment({ commit, state }: ActionContext<State, any>) {
      try {
        const { account } = (await paymentInit({
          internalInvoiceId: state.invoicePaymentData.internal_id,
          signature: state.invoicePaymentData.signature,
        })) as InitPaymentData
        commit('receiveInitPayment', { account })
      } catch (error) {
        commit('setError', { error })
      } finally {
        commit('setLoading', false, { root: true })
      }
    },

    async resetPaymentCard({ commit }: ActionContext<State, any>) {
      commit('receivePaymentCard', EMPTY_PAYMENT_CARD_DATA)
    },

    async requestPaymentCard(
      { state, commit, dispatch }: ActionContext<State, any>,
      { operationId, status }: { operationId?: number; status: string },
    ) {
      state.showCardForm = true
      const startCheckInvoiceStatusLoop = () => {
        setTimeout(() => {
          dispatch('checkInvoiceStatus', {
            invoiceId: state.invoicePaymentData.internal_id,
          })
        }, INVOICE_STATUS_CHECK_POST_PAYMENT_DELAY)
      }

      try {
        const paymentCardData = await paymentCard({
          amount: state.invoicePaymentData.totalAmount,
          invoiceInternalId: state.invoicePaymentData.internal_id,
          paymentExternalId: state.paymentCardData.payment_external_id,
          paymentInternalId: state.paymentCardData.payment_internal_id,
          operationId,
          status,
          email: state.email,
          phone: state.phone,
          signature: state.invoicePaymentData.signature,
        })

        commit('receivePaymentCard', paymentCardData)

        if ((paymentCardData as PaymentCardData).status === 'NEW') {
          const options = {
            account: state.initPaymentData.account,
            transactionId: String(state.paymentCardData.payment_internal_id),
            amount: state.paymentCardData.amount,
          }
          // console.log(options)
          const acquireAlias = state.invoicePaymentData.acquirerAlias

          if (acquireAlias === 'MONETA') {
            // см. https://docs.payanyway.ru/payment-form-v2/
            // @ts-ignore
            const assistant = new window.Assistant.Builder()

            assistant.build(options, 'payment-form')
          }

          startCheckInvoiceStatusLoop()
        }
      } catch (error) {
        commit('setError', { error })
      }
    },

    async startApplePay({ state, commit }: ActionContext<State, any>) {
      const amount = state.invoicePaymentData.totalAmount

      invariant(
        state.invoicePaymentData.platformAlias,
        '`platformAlias` value is required for ApplePay',
      )

      startApplePay({
        amount,
        internalInvoiceId: state.invoicePaymentData.internal_id,
        email: state.email,
        phone: state.phone,
        label: state.invoicePaymentData.platformAlias,
        signature: state.invoicePaymentData.signature,
        callback: async ({ error, paymentInternalId, payment }) => {
          if (error) {
            commit('setError', { error })
            return
          }
          if (paymentInternalId) {
            commit('setApplepayData', { paymentInternalId })
            return
          }
          if (!payment) {
            throw new Error('Payment is undefined')
          }
          // проведение платежа через платежный шлюз
          const { status } = (await paymentApplepay({
            amount,
            internalInvoiceId: state.invoicePaymentData.internal_id,
            paymentInternalId: state.applepayData.payment_internal_id,
            paymentExternalId: null,
            email: state.email,
            phone: state.phone,
            status: 'PROGRESS',
            signature: state.invoicePaymentData.signature,
            datagram: JSON.stringify(payment),
          })) as ApplepayProgressData
          if (status === 'SUCCESS') {
            commit('setSuccess')
            return
          }
          commit('setError', { error: new Error('Не удалось выполнить платеж') })
        },
      })
    },

    async waitForIsReadyToGooglePay({ commit }: ActionContext<State, any>) {
      const isReadyToPay = await getIsReadyToGooglePay()
      commit('setIsReadyToGooglePay', isReadyToPay)
    },

    async prefetchGooglePay() {
      // @ts-ignore
      await prefetchGooglePaymentData({
        merchantId: googlepayMerchantId,
        merchantName: googlepayMerchantName,
      })
    },

    async startGooglePay({ state, commit }: ActionContext<State, any>) {
      const amount = state.invoicePaymentData.totalAmount
      const gateway = gateways[state.invoicePaymentData.acquirerAlias as AcquireAlias]
      if (!gateway) {
        throw new Error(
          `gateway form ${state.invoicePaymentData.acquirerAlias} is not defined`,
        )
      }
      startGooglePay(
        {
          gateway,
          gatewayMerchantId: String(state.initPaymentData.account),
          merchantId: googlepayMerchantId,
          merchantName: googlepayMerchantName,
          totalPrice: String(amount),
        },
        async (paymentData) => {
          const token = paymentData.paymentMethodData.tokenizationData.token

          try {
            const result = (await paymentGooglepay({
              amount,
              internalInvoiceId: state.invoicePaymentData.internal_id,
              paymentInternalId: state.googlepayData.payment_internal_id,
              paymentExternalId: null,
              email: state.email,
              phone: state.phone,
              status: 'PROGRESS',
              signature: state.invoicePaymentData.signature,
              datagram: token,
            })) as GooglepayProgressData

            return result
          } catch (error) {
            const message = processAxiosError(error)
            throw new Error(message)
          }
        },
        () => {
          commit('setSuccess')
        },
        (error) => {
          // ничего не делаем
        },
      )

      const { payment_internal_id } = (await paymentGooglepay({
        amount,
        internalInvoiceId: state.invoicePaymentData.internal_id,
        paymentInternalId: state.applepayData.payment_internal_id,
        paymentExternalId: null,
        email: state.email,
        phone: state.phone,
        status: 'NEW',
        signature: state.invoicePaymentData.signature,
      })) as GooglepayNewData

      commit('setGooglepayData', { paymentInternalId: payment_internal_id })
    },
  },
  mutations: {
    closePaymentForms(state: State) {
      state.showSbpQrcode = false
      state.showCardForm = false
    },
    receive(state: State, data: InvoicePaymentData) {
      state.invoicePaymentData = data
    },

    receiveInvoicePaymentData(state: State, paymentData: InvoicePaymentData) {
      state.invoicePaymentData = { ...paymentData }
    },

    receiveMerchantPermission(state: State, { result }: { result: any }) {
      const url =
        result === 'success'
          ? state.confirmData.acceptedReturnUrl
          : state.confirmData.refusedReturnUrl
      window.location.replace(url as string)
    },

    receiveInitPayment(state: State, { account }: { account: number }) {
      state.initPaymentData = {
        account,
      }
    },

    receivePaySbp(
      state: State,
      { sbpData, getters }: { sbpData: PaymentSbpData; getters: any },
    ) {
      state.sbpData = { ...sbpData }
      if (getters.isDesktop) {
        QRCode.toCanvas(document.getElementById('sbp-qrcode'), sbpData.sbp_url, (err) => {
          if (err) {
            console.error(err)
          }
        })
        state.showSbpQrcode = true
        return
      }
      // @ts-ignore
      window.slideUpWidget.openWidget(sbpData.sbp_url)
    },

    receivePaymentCard(state: State, paymentCardData: PaymentCardData) {
      state.paymentCardData = paymentCardData
    },

    setApplepayData(
      state: State,
      {
        paymentInternalId,
      }: { paymentInternalId: ApplepayNewData['payment_internal_id'] },
    ) {
      state.applepayData.payment_internal_id = paymentInternalId
    },

    setGooglepayData(
      state: State,
      {
        paymentInternalId,
      }: { paymentInternalId: GooglepayNewData['payment_internal_id'] },
    ) {
      state.googlepayData.payment_internal_id = paymentInternalId
    },

    setIsReadyToGooglePay(state: State, isReadyToPay: boolean) {
      state.googlepayData.isReadyToPay = isReadyToPay
    },

    setConfirmData(
      state: State,
      {
        acquirer_alias,
        permission_request_id,
        merchantId,
        acceptedReturnUrl,
        refusedReturnUrl,
      }: State['confirmData'],
    ) {
      state.confirmData.acquirer_alias = acquirer_alias
      state.confirmData.permission_request_id = permission_request_id
      state.confirmData.merchantId = merchantId
      state.confirmData.acceptedReturnUrl = acceptedReturnUrl
      state.confirmData.refusedReturnUrl = refusedReturnUrl
    },

    setError(state: State, { error }: { error: Error | AxiosError }) {
      let message = ''

      if (axios.isAxiosError(error)) {
        message = processAxiosError(error)
      } else {
        // Ошибки платежа упрощаются
        console.error(error.message)
        message = PAYMENT_ERROR_MESSSAGES[error.message] || DEFAULT_ERROR_MESSAGE
      }

      state.error = message
    },

    setSuccess(state: State) {
      state.invoicePaymentData.status = 'success'
    },

    setEmail(state: State, { email }: { email: string }) {
      state.email = email
    },
    setPhone(state: State, { phone }: { phone: string }) {
      state.phone = phone
    },
    setSendType(state: State, { sendType }: { sendType: string }) {
      state.sendType = sendType
    },
  },
}
