import { paymentApplepay, ApplepayNewData } from '@/api/fns'

const isDevelopment = process.env.NODE_ENV === 'development'

export const isApplePayAvailable = () => {
  if (isDevelopment) {
    return true
  }

  return typeof ApplePaySession !== 'undefined' && ApplePaySession.canMakePayments()
}

export const startApplePay = async ({
  amount,
  internalInvoiceId,
  email,
  phone,
  label,
  signature,
  callback,
}: {
  amount: number
  internalInvoiceId: string
  email: any
  phone: any
  label: string
  signature: string
  callback: ({
    error,
    paymentInternalId,
    payment,
  }: {
    error?: Error
    paymentInternalId?: string
    payment?: ApplePayJS.ApplePayPayment
  }) => void
}) => {
  if (typeof ApplePaySession === 'undefined') {
    if (isDevelopment) {
      console.warn('ApplePaySession is undefined')
    }
    return
  }

  const request: ApplePayJS.ApplePayPaymentRequest = {
    countryCode: 'RU',
    currencyCode: 'RUB',
    merchantCapabilities: ['supports3DS'],
    supportedNetworks: ['masterCard', 'visa'],
    total: {
      // разделитель разрядов - точка
      amount: String(amount),
      label,
      type: 'final',
    },
  }

  const session = new ApplePaySession(3, request)

  session.onvalidatemerchant = async (
    event: ApplePayJS.ApplePayValidateMerchantEvent,
  ) => {
    try {
      const newData = (await paymentApplepay({
        amount,
        internalInvoiceId,
        paymentInternalId: null,
        paymentExternalId: null,
        status: 'NEW',
        email,
        phone,
        signature,
        validationURL: event.validationURL,
      })) as ApplepayNewData
      session.completeMerchantValidation(newData.session)
      callback({ paymentInternalId: newData.payment_internal_id })
    } catch (error) {
      session.abort()
      callback({ error: error as Error })
    }
  }

  // см. https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778020-onpaymentauthorized
  session.onpaymentauthorized = async (
    event: ApplePayJS.ApplePayPaymentAuthorizedEvent,
  ) => {
    const payment = event.payment

    try {
      await callback({ payment })
      session.completePayment({
        status: ApplePaySession.STATUS_SUCCESS,
      })
    } catch (error) {
      session.completePayment({
        status: ApplePaySession.STATUS_FAILURE,
      })
      callback({ error: error as Error })
    }
  }

  session.begin()
}
