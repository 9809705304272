<template>
  <main class="fns-pay">
    <h1>Добавление карты для выплаты НПД</h1>

    <section class="fns-pay-info">
      <div class="fns-info-item">
        <div class="fns-info-item__name">Дата добавление</div>
        <div class="fns-info-item__value">
          {{
            $formatDate(new Date(), {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            })
          }}
        </div>
      </div>
    </section>

    <section v-if="url !== null">
      <h2>Информация о карте</h2>
      <iframe class="card-bind-frame" :src="url"></iframe>
    </section>

    <section class="fns-pay-desc">
      Без комиссии. Безопасность платежей обеспечена.
    </section>

    <section class="fns-pay-offer">
      <div><a href="http://qubiq.online/legal">Пользовательское соглашение</a></div>
      <div><a href="http://qubiq.online/legal">Политика конфиденциальности</a></div>
    </section>

    <section class="fns-pay-services">
      <div
        v-for="ps in payServices"
        :key="ps"
        class="fns-pay-services__service"
        :class="'fns-pay-services__service_' + ps"
      >
        <img :src="require(`@/assets/img/${ps}.svg`)" :alt="`${ps} icon`" />
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FnsBindCard',

  data: () => ({
    payServices: ['visa', 'mastercard', 'maestro', 'mir'],
  }),

  computed: {
    ...mapGetters({
      url: 'bind/url',
    }),
  },
}
</script>
