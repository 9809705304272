











export default {
  name: 'FnsSuccess',
  props: ['message'],
}
