export function processAmount({ oldVal, newVal, decSep }) {
  let newValFixed = newVal.replace(/[,.]/, decSep)
  const fixedDecSep = decSep === '.' ? '\\.' : decSep
  const rgCheck = new RegExp(`^\\d+(${fixedDecSep}\\d{0,2})?$`)
  if (!newValFixed || rgCheck.test(newValFixed)) {
    return newValFixed
  }
  return oldVal
}

export function getDecimalSeparator() {
  const n = 1.1
  const decSep = n.toLocaleString()[1]
  return decSep
}

export function getLang() {
  if (navigator.languages != undefined) return navigator.languages[0]
  return navigator.language
}

export function validateEmail(email) {
  const emailRegex =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zа-яё0-9](?:[a-zа-яё0-9-]*[a-zа-яё0-9])?\.)+[a-zа-яё0-9](?:[a-zа-яё0-9-]*[a-zа-яё0-9])?$/i
  return emailRegex.test(email)
}

export function validatePhone(locale, phone) {
  switch (locale) {
    case 'ru': {
      const re = /^\+7\s\(\d\d\d\)\s\d\d\d-\d\d-\d\d$/
      return re.test(phone)
    }
    default: {
      throw new Error(`Locale ${locale} is not supported`)
    }
  }
}
