import { Block } from '@/constants'
import { GET_INVOICE } from '@/store/actions.type'
import {
  FEEDBACK_CHANGE,
  CART_BLOCK_CHANGE,
  FEEDBACK_EMPTY_CHANGE,
  SET_INVOICE,
} from '../mutations.type'
import { getInvoice } from '@/api/template'

export default {
  state: {
    feedback: [],
    cartBlock: null,
    feedBackNotEmpty: true,
    invoice: null,
  },
  mutations: {
    [FEEDBACK_CHANGE]: (state, data) => (state.feedback = data),
    [CART_BLOCK_CHANGE]: (state, data) => (state.cartBlock = data),
    [FEEDBACK_EMPTY_CHANGE]: (state, data) => (state.feedBackNotEmpty = data),
    [SET_INVOICE]: (state, data) => (state.invoice = data),
  },
  getters: {
    feedbackBlock: (state, getters, { page, route }) =>
      page.getBlock(Block.FeedbackBlock, route.current),
    paymentMethods: (state, getters, { page, route }) =>
      page.getBlock(Block.PaymentMethodsBlock, route.current),
    paymentBlock: (state, getters, { page }) =>
      page.getBlock(Block.CartBlock),
    feedbackValue: (state) => state.feedback,
    feedBackNotEmpty: (state) => state.feedBackNotEmpty,
    invoiceData: (state) => state.invoice,
  },
  actions: {
    async [GET_INVOICE]({ commit }, invoice_id) {
      const data = await getInvoice(invoice_id)
      commit(SET_INVOICE, data)
    },
  },
}
