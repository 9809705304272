import { mapActions, mapState } from 'vuex'
import { REQUEST_PAGE } from '@/store/actions.type'
import Header from '@/components/blocks/Header.vue'
import Merchant from '@/components/blocks/Merchant.vue'

export default {
  components: {
    Header,
    Merchant,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.isLoading,
    }),
  },
  methods: {
    ...mapActions({
      requestPage: REQUEST_PAGE,
    }),
  },
  async beforeMount() {
    await this.requestPage({})
  },
}
