import axios from 'axios'
import { invoicePaymentData, cfg } from '@/api/fns'

export interface Body {
  name: string
  value: any
  required: boolean
}

export interface Meta {
  constructor_editable: boolean
  page_editable: boolean
}

export interface Block {
  name: string
  body: Body[]
  meta: Meta
}

export interface Page {
  name: string
  blocks: Block[]
}

export interface ITemplateData {
  template_id: string
  name: string
  case: string
  pages: Page[]
  getBlock(blockName: string, pageName?: string): Block | null
}

export class TemplateData implements ITemplateData {
  template_id!: string
  name!: string
  case!: string
  pages!: Page[]

  constructor(data: ITemplateData) {
    this.template_id = data.template_id
    this.name = data.name
    this.case = data.case
    this.pages = data.pages
  }

  getBlock(blockName: string, pageName?: string): Block | null {
    return (
      this.pages
        .find((page) => page.name === (pageName ? pageName : this.case))
        ?.blocks.find((b) => b.name === blockName) || null
    )
  }
}

export interface CheckoutResult {
  acquirerAlias: string
  acquirerId: string
  customerType: string
  date: string
  error: string
  id: string
  inn: string
  internal_id: string
  invoiceNumber: number
  merchantId: string
  merchantName: string
  merchant_internal_id: number
  platformAlias: string
  platform_id: null | number | string
  saleInfo: any
  states: string
  totalAmount: number
}

export const getTemplate = async (templateId?: string): Promise<TemplateData> => {
  try {
    const _templateId = templateId
      ? templateId
      : JSON.parse(<string>localStorage.getItem('templateId'))
    // const data = await require('@/api/stub/stream-alert.json')
    // const data = await require('@/api/stub/tickets.json')
    // const data = await require('@/api/stub/test.json')
    const { data } = await axios.get(`/pages/${_templateId}`, cfg)
    return new TemplateData({ template_id: _templateId, ...data })
  } catch (err: any) {
    if (err.response?.data) {
      const error = err.response.data.detail || err.response?.data?.error?.desc
      throw new Error(error)
    }
    throw err
  }
}

export const sendOrder = async ({
  cartBlock,
  payMethod,
}: {
  cartBlock: object
  payMethod: string
}) => {
  try {
    // @ts-ignore
    const { template_id } = cartBlock
    // @ts-ignore
    if (cartBlock.case == 'fix') {
      const internal_id = localStorage.getItem('internal_id')
      //@ts-ignore
      const data = await invoicePaymentData(internal_id)

      return data
    }
    const { data } = await axios.post(
      `/pages/${template_id}/invoices${payMethod === 'order' ? '?no_pay=true' : ''}`,
      cartBlock,
      cfg,
    )
    return data
  } catch (err: any) {
    if (err.response?.data) {
      const error = err.response.data.detail || err.response?.data?.error?.desc
      throw new Error(error)
    }
    throw err
  }
}

export const postInvoiceNumber = async (data: Object) => {
  const templateId = JSON.parse(<string>localStorage.getItem('templateId'))
  const invoice = await axios.post(`/pages/${templateId}/invoices`, data, cfg)
  return invoice
}

export const getInvoice = async (invoice_id: string): Promise<CheckoutResult> => {
  try {
    const { data } = await axios.get(`/invoice/${invoice_id}/payment_data`, cfg)
    return data as CheckoutResult
  } catch (err: any) {
    if (err.response?.data) {
      const error = err.response.data.detail || err.response?.data?.error?.desc
      throw new Error(error)
    }
    throw err
  }
}
